import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

export const fetchEvents = createAsyncThunk("events/fetchEvents", async () => {
  const getEvents = httpsCallable(functions, "getEvents");
  const result = await getEvents();
  return result.data;
});

export const fetchEvent = createAsyncThunk(
  "events/fetchEvent",
  async ({ institutionName, eventName }) => {
    const getEvent = httpsCallable(functions, "getEvent");
    const result = await getEvent({
      institutionName,
      eventName,
    });
    return result.data;
  }
);

export const fetchSession = createAsyncThunk(
  "events/fetchSession",
  async ({ institutionName, eventName, sessionname }) => {
    const getSession = httpsCallable(functions, "getSession");
    const result = await getSession({
      institutionName,
      eventName,
      sessionname,
    });
    return result.data;
  }
);

const eventsSlice = createSlice({
  name: "events",
  initialState: {
    data: null,
    loading: false,
    error: null,
    event: null,
    sesion: null,
  },
  reducers: {
    fetchEventsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchEventsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchEventsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEvents.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEvent.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.event = action.payload;
      })
      .addCase(fetchEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(fetchSession.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSession.fulfilled, (state, action) => {
        state.loading = false;
        state.sesion = action.payload;
      })
      .addCase(fetchSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const events = (state) => state.events.data;
export const event = (state) => state.events.event;
export const sesion = (state) => state.events.sesion;
export const loading = (state) => state.events.loading;

export const eventsSelector = createSelector(events, (data) => {
  return data;
});

export const eventSelector = createSelector(event, (event) => {
  return event;
});

export const sesionSelector = createSelector(sesion, (sesion) => {
  return sesion;
});

export const loadingSelector = createSelector(loading, (loading) => {
  return loading;
});

export const { fetchEventsStart, fetchEventsSuccess, fetchEventsFailure } =
  eventsSlice.actions;
export default eventsSlice.reducer;
