import React, { useState } from "react";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import ActionLoadingAnimation from "../assets/images/ActionLoadingAnimation.gif";
import { toast } from "react-toastify";

const Absent = ({ member, institutionName, eventName, sessionName }) => {
  const [checkingIn, setCheckingIn] = useState(false);
  const handleManualCheckin = async () => {
    try {
      const manualCheckin = httpsCallable(functions, "manualCheckin");
      const user = { name: member.name, email: member.email, id: member.id };
      const session = { institutionName, eventName, sessionName };
      setCheckingIn(true);
      try {
        await manualCheckin({ user, session });
      } catch (err) {
        toast.error(`failed to checkin member 🙁 ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }

      setCheckingIn(false);
    } catch (error) {
      console.error("Error getting session data:", error);
    }
  };

  return (
    <div className="w-full flex justify-center"> 
    <div className="w-full max-w-3xl flex items-center justify-between p-4">
      <p className="text-xl">{member.name}</p>
      <button
        disabled={checkingIn}
        className="flex justify-center items-center object-contain bg-primary text-white rounded-xl p-2 w-20 h-10"
        onClick={handleManualCheckin}
      >
        {!checkingIn ? (
          "Checkin"
        ) : (
          <img alt="loading animation" src={ActionLoadingAnimation} />
        )}
      </button>
    </div>
    </div>
  );
};

export default Absent;
