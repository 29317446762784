import { useDispatch, useSelector } from "react-redux";

import {
  eventsSelector,
  eventSelector,
  sesionSelector,
  loadingSelector,
  fetchEvents,
  fetchEvent,
  fetchSession,
} from "./eventsSlice.js";

import { useCallback } from "react";

export const useEventsSelector = () => {
  return useSelector(eventsSelector);
};
export const useEventSelector = () => {
  return useSelector(eventSelector);
};
export const useSesionSelector = () => {
  return useSelector(sesionSelector);
};
export const useLoadingSelector = () => {
  return useSelector(loadingSelector);
};

export const useFetchEvents = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchEvents());
  }, [dispatch]);
};

export const useFetchEvent = () => {
  const dispatch = useDispatch();
  return useCallback(
    (institutionName, eventName) => {
      dispatch(fetchEvent({ institutionName, eventName }));
    },
    [dispatch]
  );
};

export const useFetchSesion = () => {
  const dispatch = useDispatch();
  return useCallback(
    (institutionName, eventName, sessionname) => {
      dispatch(fetchSession({ institutionName, eventName, sessionname }));
    },
    [dispatch]
  );
};
