import { Link, NavLink , useMatch  } from "react-router-dom";
import { auth } from "../firebase";
import wordLogo from "../assets/images/wordLogo.png";
import AuthenticationModal from "../Components/Modals/AuthenticationModal";
import { useState } from "react";
const NavBar = () => {
  const matchSessionScreen = useMatch({
    path: "/session/:institutionName/:eventname/:sessionname",
    strict: true,
    sensitive: true
  });
  
  const [authenticationModalActive, setAuthenticationModalActive] = useState(false);

  const handleCloseAuthenticationModal = () => {
    setAuthenticationModalActive(false);
  };
  const handleShowAuthenticationModal = () => {
    setAuthenticationModalActive(true);
  };

  const user = auth.currentUser;

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {})
      .catch((error) => alert(error.message));
  };

  const handleShowMenu = () => {
    document.getElementById("menu").classList.toggle("hidden");
  };

  return (
    <div id="navbar" className="w-full relative h-14 flex items-center ">
      {authenticationModalActive && (
        <AuthenticationModal onClose={handleCloseAuthenticationModal} />
      )}
      <div className="px-4 border-none relative border-gray-200 mx-auto flex w-full justify-between items-center">
        <NavLink to={"/"} className="flex font-extrabold text-xl">
          <img className="w-44 px-4" src={wordLogo} alt="I-Checkin word logo" />
        </NavLink>
        <div
          id="menu"
          className="absolute left-0 md:relative md:w-auto border md:rounded-none items-center md:py-0 py-5 md:shadow-none shadow-xl border-gray-200 md:border-transparent w-full z-20 md:flex md:bg-transparent md:top-auto top-0 md:mt-0 mt-14 bg-white hidden"
        >
          <nav className="md:space-x-6 lg:space-x-6 flex md:flex-row flex-col h-full font-medium">
            {!user && (
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://apps.apple.com/app/i-checkin/id6463780727"
                  className="flex justify-center md:w-auto w-full md:py-0 py-3 text-center"
                >
                  <img
                    className="w-24"
                    src="/appstore-badge.svg"
                    alt="icheckin-apple"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.sobow.icheckin"
                  className="flex justify-center md:w-auto w-full md:py-0 py-3 text-center"
                >
                  <img
                    className="w-24"
                    src="/googleplay-badge.png"
                    alt="icheckin-google"
                  />
                </a>
              </>
            )}
       {matchSessionScreen &&                       <Link className="md:w-auto w-full md:py-0 py-3 text-center"
                        to={`/event/${matchSessionScreen.params.institutionName}/${matchSessionScreen.params.eventname}`}
                      >
                       Back To Event Dashboard
                      </Link>}
          </nav>
          <div className="w-1 border-l border-gray-200 h-5 md:ml-6 lg:ml-10" />
          <div className="h-full font-medium flex items-center md:flex-row flex-col md:pl-3 lg:pl-3 mx-3">
            {user ? (
              <div
                onClick={handleSignOut}
                className="group px-3 md:mr-0 mr-5 ml-5 text-center lg:ml-7 py-1 flex items-center border border-gray-900 hover:text-gray-100 hover:bg-gray-900 hover:cursor-pointer transition-colors ease-out duration-100 rounded-xl"
              >
                <svg
                  className="group-hover:invert"
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" />
                </svg>
                log out
              </div>
            ) : (
              <div
                onClick={handleShowAuthenticationModal}
                className="group hover:cursor-pointer px-3 md:mr-0 mr-5 ml-5 text-center lg:ml-7 py-1 flex items-center border border-gray-900 hover:text-gray-100 hover:bg-gray-900 transition-colors ease-out duration-100 rounded-xl"
              >
                <svg
                  className="group-hover:invert"
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                </svg>{" "}
                member login
              </div>
            )}
          </div>
        </div>
        <div
          onClick={handleShowMenu}
          className="md:hidden mx-4 select-none block cursor-pointer hover:bg-gray-100 p-1.5 rounded-md"
        >
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
