import { useState, useEffect, useRef } from "react";
import { QRCodeSVG } from "qrcode.react";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
// import wordLogo from "../assets/images/wordLogo.png";
import { toast } from "react-toastify";
import LoadingAction from "../Components/LoadingAction";

export default function QRCode() {
  const [startingSession, setStartingSession] = useState(true);
  const qrChangeInterval = useRef();

  const urlParams = new URLSearchParams(window.location.search);
  const institution = urlParams.get("i");
  const event = urlParams.get("e");
  const session = urlParams.get("s");
  const session_duration = urlParams.get("d");
  const session_uid = urlParams.get("uid");

  const [QRinfo, setQRinfo] = useState({
    institution: institution,
    event: event,
    uid: session_uid,
  });

  // const [secondsLeft, setSecondsLeft] = useState(0);

  const activateSession = async () => {
    // console.log("activating session💢");
    try {
      const activateSession = httpsCallable(functions, "activateSession");
      await activateSession({
        institutionName: institution,
        eventName: event,
        sessionName: session,
      });
      setStartingSession(false);
    } catch (err) {
      toast.error(`failed to start the session 💔 ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    const header = document.getElementById("navbar");
    const footer = document.getElementById("footer");
    header.style = "display: none";
    footer.style = "display: none";
    const qrContainer = document.getElementsByTagName("svg")[0];
    qrContainer.style = "width:100%;height:fit-content;max-width: 500px;";

    activateSession().then(() => {
      // console.log("finisehd activating session 💤");
      if (!qrChangeInterval.current) {
        handleUidChange();
        // setSecondsLeft(session_duration);
        qrChangeInterval.current = setInterval(
          handleUidChange,
          session_duration * 1000
        );
      }
    });
  }, []);

  // useEffect(() => {
  //   if (secondsLeft <= 0) return;
  //   const timeout = setTimeout(() => {
  //     setSecondsLeft((prev) => prev - 1);
  //   }, 1000);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [secondsLeft]);

  const handleUidChange = () => {
    const updateSessionUid = httpsCallable(functions, "updateSessionUid");
    updateSessionUid({
      institutionName: institution,
      eventName: event,
      sessionname: session,
    }).then((result) => {
      setQRinfo((prev) => ({ ...prev, uid: result.data.newUid }));
      // clearInterval(qrChangeInterval);
      // setSecondsLeft(session_duration);
    });
  };

  return (
    <div className='flex  min-h-screen w-full h-full justify-center items-center p-8 bg-background'>
      {startingSession ? (
        <LoadingAction text={"activating session"} transparency={100} />
      ) : (
        <div className='flex flex-col-reverse h-full w-fit rounded-xl bg-white'>
          <div className='flex flex-col items-center justify-center border-t-2 border-grayish w-full '>
            <div className='flex flex-col justify-center items-center gap-10 w-full p-4 lg:px-20'>
              <p className='text-3xl lg:text-5xl'>scan with</p>
              {/*<img src={wordLogo} className="w-64 " alt="icheckin word logo" />*/}
              <div className='flex flex-col lg:flex-row gap-4'>
                <a>
                  <img
                    className='h-16 object-contain'
                    src='/appstore-badge.svg'
                    alt='icheckin-apple'
                  />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.sobow.icheckin'
                  rel='noreferrer'
                  target='_blank'
                >
                  <img
                    className='h-16 object-contain'
                    src='/googleplay-badge.png'
                    alt='icheckin-google'
                  />
                </a>
              </div>
            </div>
          </div>
          <div className='flex w-full flex-col justify-center items-center'>
            <div className='flex max-h-screen justify-center items-center w-full p-6 lg:px-20'>
              <QRCodeSVG
                value={JSON.stringify(QRinfo)}
                size={400}
                style={{ height: "auto", maxWidth: "100%" }}
                bgColor={"none"}
                fgColor={"#000000"}
                level={"Q"}
                includeMargin={false}
              />
            </div>
            <div className='flex gap-2 lg:gap-4 w-full p-6 lg:px-10 '>
              <p className='flex justify-center items-center p-6 flex-1 h-12 lg:h-16 bg-primary rounded-3xl text-black text-base  '>
                {event}
                <br />
                {session}
              </p>
              {/* <p className="text-center p-6 flex justify-center items-center w-12 h-12 lg:w-16 lg:h-16 rounded-full text-white text-md lg:text-2xl bg-primary">
              {secondsLeft}
            </p> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
