import React from "react";

const Participant = ({ email, name, role, removeParticipant, changeRole }) => {
  const role_color = role === "o" ? "bg-yellow-300" : "bg-grayish";
  return (
    <div className="flex w-full h-20 rounded-xl bg-white justify-between items-center p-6">
      <p className="text-xl">{name}</p>
      <p className="text-xl">{email}</p>
      <p
        onClick={() => changeRole(email)}
        className={`text-xl p-2 rounded-md ${role_color}`}
      >
        {role === "p" ? "participant" : role === "o" ? "owner" : "uknown role"}
      </p>
      <div
        className="flex justify-center w-6 h-6 text-center items-center text-white text-2xl rounded-full bg-red-500"
        onClick={() => removeParticipant(email)}
      >
        {" "}
        <span className="material-symbols-rounded">remove</span>
      </div>
    </div>
  );
};

export default Participant;
