import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { auth , functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { toast } from "react-toastify";

const AuthenticationModal = ({ onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [display, setDisplay] = useState("login");

  const handleLogin = (data) => {

    const email = data.email;
    const password = data.password;

    const getUserRole = httpsCallable(functions, "getUserRole");
    getUserRole({email:data.email}).then(result => {
      if(result.data.role === 'o'){
 signInWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        const user = userCredentials.user;
        toast.success("Welcome to I-Checkin !", {
          position: toast.POSITION.TOP_CENTER,
        });
        onClose();
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          toast.error("email not found 🙁 please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (error.code === "auth/wrong-password") {
          toast.error("wrong password 😔 please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (error.code === "auth/invalid-login-credentials") {
          toast.error("invalid login credentials 😢 please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error("an error has occurred 😢 please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
      }
      else{
        toast.error("you are not an owner in any event 🙁 request access or login in the app.", {
           position: toast.POSITION.TOP_CENTER,
          });
      }
    })
  };

  const handleSignUp = (data) => {

    const email = data.email;
    const password = data.password;

    const getUserRole = httpsCallable(functions, "getUserRole");
    getUserRole({email:data.email}).then(result => {
      if(result.data.role  === 'o'){
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredentials) => {
          const user = userCredentials.user;
          toast.success("Welcome to I-Checkin !", {
            position: toast.POSITION.TOP_CENTER,
          });
          onClose();
        })
        .catch((error) => {
          toast.error("failed to create account 😢 please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
      }
      else{
        toast.error("you are not an owner in any event 🙁 request access or login in the app.", {
          position: toast.POSITION.TOP_CENTER,
         });
      }
    });
    
  };

  const handlePasswordReset = (data) => {
    const email = data.email;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((error) => {
        toast.error(
          "failed to send a password reset link 😢 please try again.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  return (
    <div
      id="authentication-modal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed p-2 top-0 left-0 right-0 z-50 overflow-x-hidden overflow-y-auto md:inset-0 w-screen h-screen bg-black bg-opacity-10 flex justify-center items-center"
    >
      <div class="relative bg-white rounded-lg shadow w-full max-w-xl py-8 ">
        <button
          onClick={onClose}
          type="button"
          class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-md w-8 h-8 ml-auto inline-flex justify-center items-center"
          data-modal-hide="authentication-modal"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="px-6 py-6 lg:px-8">
          <h3 class="mb-8 text-2xl font-medium text-gray-900">
            {display === "login"
              ? "Members Login"
              : display === "forgot"
              ? "Reset Your Password"
              : display === "register"
              ? "Create a new account"
              : ""}
          </h3>
          <form class="space-y-6" action="#">
            <div>
              <div className="relative flex items-center mb-4">
                <Controller
                  rules={{
                    maxLength: {
                      value: 30,
                      message: "the email is too long 😰",
                    },
                    required: "email is required",
                    pattern: {
                      message: "Please enter a valid email address 🥺",
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    },
                  }}
                  control={control}
                  name="email"
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <div className="w-full flex flex-col">
                      {error && (
                        <p className="error">{error.message || "error"}</p>
                      )}
                      <div className="w-full flex items-center">
                        <svg
                          className="absolute ml-4 w-8 h-8 stroke-current text-gray-200 mt-0.5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                        <input
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          type="text"
                          placeholder="Email"
                          className="w-full py-5 border border-gray-200 rounded-md pl-14 bg-gray-50 focus:bg-white focus:ring-green-300 focus:ring-2 focus:ring-offset-2 focus:ring-opacity-90 focus:outline-none tails-selected-element"
                          contenteditable="true"
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
            <div>
              {(display === "register" || display === "login") && (
                <div className="relative flex items-center mb-4">
                  <Controller
                    rules={{
                      maxLength: {
                        value: 24,
                        message: "the password is too long 😰",
                      },
                      required: "password is required",
                      minLength: {
                        value: 6,
                        message: "the minimum password length is 6 🤬",
                      },
                    }}
                    control={control}
                    name="password"
                    render={({
                      field: { value, onChange, onBlur },
                      fieldState: { error },
                    }) => (
                      <div className="w-full flex flex-col">
                        {error && (
                          <p className="error">{error.message || "error"}</p>
                        )}
                        <div className="w-full flex items-center">
                          <svg
                            className="absolute ml-4 w-8 h-8 stroke-current text-gray-200 mt-0.5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            />
                          </svg>
                          <input
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            type="password"
                            placeholder="Password"
                            className="w-full py-5 border border-gray-200 rounded-md pl-14 bg-gray-50 focus:bg-white focus:ring-green-300 focus:ring-2 focus:ring-offset-2 focus:ring-opacity-90 focus:outline-none"
                          />
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
            </div>
            <div class="flex justify-end">
              <p
                onClick={
                  display === "forgot"
                    ? () => setDisplay("login")
                    : display === "login"
                    ? () => setDisplay("forgot")
                    : ""
                }
                class="text-md text-primary hover:underline cursor-pointer"
              >
                {display === "login"
                  ? "forgot password?"
                  : display === "forgot"
                  ? "back to login"
                  : ""}
              </p>
            </div>
            <button
              onClick={
                display === "login"
                  ? handleSubmit(handleLogin)
                  : display === "register"
                  ? handleSubmit(handleSignUp)
                  : display === "forgot"
                  ? handleSubmit(handlePasswordReset)
                  : ""
              }
              type="submit"
              class="w-full text-white bg-primary hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center"
            >
              {display === "login"
                ? "Login"
                : display === "forgot"
                ? "Send Reset Link"
                : display === "register"
                ? "Register"
                : ""}
            </button>
            <div class="text-md font-medium text-gray-500">
              {display === "register"
                ? "Have an account? "
                : display === "login"
                ? "Not Registered? "
                : ""}
              <p
                onClick={
                  display === "login"
                    ? () => setDisplay("register")
                    : display === "register"
                    ? () => setDisplay("login")
                    : ""
                }
                class="text-primary hover:underline inline cursor-pointer"
              >
                {display === "register"
                  ? "Login"
                  : display === "login"
                  ? "Create An Account"
                  : ""}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationModal;
