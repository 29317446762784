import React, { useEffect } from "react";
import { useState } from "react";
import Papa from "papaparse";
import Participant from "../Participant";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import LoadingAction from "../LoadingAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddMembersModal = ({ onClose, institution, event }) => {
  const [participants, setParticipants] = useState([]);
  const [adding, setAdding] = useState(false);
  let commonConfig = { delimiter: "," };
  const navigate = useNavigate();

  const handleAddParticipant = (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const name = e.target.elements.name.value;
    setParticipants((prev) => [
      ...prev,
      { email: email, name: name, role: "p" },
    ]);
    e.target.elements.email.value = "";
    e.target.elements.name.value = "";
  };
  const handleRemoveParticipant = (email) => {
    setParticipants((prev) =>
      prev.filter((prev_participant) => prev_participant.email !== email)
    );
  };
  const changeRole = (email) => {
    const participant_array = [...participants];
    const index = participant_array.findIndex(
      (participant) => participant.email === email
    );
    if (index !== -1) {
      participant_array[index].role =
        participant_array[index].role === "o" ? "p" : "o";
      setParticipants(participant_array);
    }
  };
  const handleAddParticipantFromCsv = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      ...commonConfig,
      header: true,
      complete: (result) => {
        setParticipants((prev) => [...prev, ...result.data]);
      },
    });
  };

  const handleAddMembers = async () => {
    const members = [...participants];
    const eventname = event;
    const institutionName = institution;

    const addMembers = httpsCallable(functions, "addMembers");
    try {
      setAdding(true);
      const result = await addMembers({
        institutionName: institutionName,
        eventName: eventname,
        members: members,
      });
      // console.log("result", result.data);
      setAdding(false);
      toast.success(result.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      onClose();
      navigate(`/event/${institutionName}/${eventname}`);
    } catch (err) {
      setAdding(false);
      onClose();
      toast.error(err.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div
      tabindex='-1'
      aria-hidden='true'
      class='fixed p-2 top-0 left-0 right-0 z-50 overflow-x-hidden overflow-y-auto md:inset-0 w-screen h-screen bg-black bg-opacity-10 flex justify-center items-center'
    >
      <div className='relative min-w-fit w-1/2 max-h-full mx-auto'>
        <div className='relative bg-white rounded-3xl shadow'>
          {adding && <LoadingAction text={"adding members"} />}
          <button
            onClick={onClose}
            type='button'
            className='absolute top-3 left-2.5 text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-md w-16 h-8 ml-auto inline-flex justify-center items-center opacity-100'
            data-modal-hide='authentication-modal'
          >
            <span className='material-symbols-rounded'>arrow_back</span>
            <div className='pl-2'>back</div>
          </button>
          <div className='px-6 py-6 lg:px-8 pt-10 '>
            <form
              className='flex flex-wrap w-full justify-between items-end pt-2 gap-2 lg:gap-0'
              onSubmit={handleAddParticipant}
            >
              <div className='flex flex-col w-full lg:w-1/3'>
                <p className=''>participant email</p>
                <input
                  name='email'
                  className=' w-full bg-grayish rounded-lg h-14 p-3'
                  type='email'
                />
              </div>
              <div className='flex flex-col w-full lg:w-1/3'>
                <p className=''>participant name</p>
                <input
                  name='name'
                  className='w-full bg-grayish rounded-lg h-14 p-3'
                  type='text'
                />
              </div>
              <label
                htmlFor='csvinput'
                className='bg-primary rounded-lg h-14 w-36 text-white text-lg flex items-center justify-center gap-2'
              >
                from csv{" "}
                <span className='material-symbols-rounded'>post_add</span>
              </label>
              <input
                onChange={handleAddParticipantFromCsv}
                id='csvinput'
                className='hidden'
                type='file'
              />
              <button
                type='submit'
                className='bg-primary rounded-full w-14 h-14 text-white text-xl flex items-center justify-center'
              >
                <span className='material-symbols-rounded'>add</span>
              </button>
            </form>
            <div className='flex flex-col w-full h-72 overflow-auto bg-grayish gap-2 p-2 mt-4 rounded-xl'>
              {participants
                .sort((a, b) => {
                  return a.role < b.role ? -1 : 1;
                })
                .map((participant, i) => (
                  <Participant
                    key={i}
                    name={participant.name}
                    email={participant.email}
                    role={participant.role}
                    removeParticipant={handleRemoveParticipant}
                    changeRole={changeRole}
                  />
                ))}
            </div>
            <div className='w-full h-fit flex justify-center items-center p-4'>
              <button
                onClick={handleAddMembers}
                className='bg-primary w-44 lg:w-1/3 h-16 rounded-2xl text-2xl text-white'
              >
                add members
              </button>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default AddMembersModal;
