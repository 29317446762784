import { useState, useEffect } from "react";
// import { functions } from "../firebase";
// import { httpsCallable } from "firebase/functions";
import { Link, useParams } from "react-router-dom";
// import Session from "../Components/SessionComponent";
import Member from "../Components/Member";
import SimpleBar from "simplebar-react";
import AddSessionModal from "../Components/Modals/AddSessionModal";
// import DoughnutProgress from "../Components/DoughnutProgress";
import React from "react";
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";
import LoadingAction from "../Components/LoadingAction";
import MemberModal from "../Components/Modals/MemberModal";
import Papa from "papaparse";
import AddMembersModal from "../Components/Modals/AddMembersModal";
import { toast } from "react-toastify";
// import { Timestamp } from "firebase/firestore";

// import setHours from "date-fns/setHours";
// import setMinutes from "date-fns/setMinutes";

import {
  useFetchEvent,
  useEventSelector,
  useLoadingSelector,
} from "../state/eventsHooks";

const EventDashboard = () => {
  const { institutionName, eventname } = useParams();
  const [sessions, setSessions] = useState([]);
  const [members, setMembers] = useState([]);
  const [addSessionModalActive, setAddSessionModalActive] = useState(false);
  // const [loadingEvent, setLoadingEvent] = useState(true);
  const [memberListExpanded, setMemberListExpanded] = useState(true);
  const [memberModalActive, setMemberModalActive] = useState({
    name: "",
    email: "",
    role: "",
    created: "",
    active: false,
  });
  const [addMembersModalActive, setAddMembersModalActive] = useState(false);
  // const [csvData, setCsvData] = useState([]);

  const event = useEventSelector();
  const loading = useLoadingSelector();
  const callFetchEvent = useFetchEvent();

  useEffect(() => {
    callFetchEvent(institutionName, eventname);
  }, []);

  useEffect(() => {
    if (event) fetchData(event);
  }, [event]);

  const handleExportCSV = () => {
    const sortsessions = [...sessions].sort((a, b) => {
      // const adate = new Date(a.date);
      // const bdate = new Date(b.date);
      return a.date - b.date;
    });

    const csvData = [];
    members.forEach((member) => {
      const obj = {};
      const email = member.email.toLowerCase();
      obj["Name"] = member.name;
      obj["Email"] = member.email.toLowerCase();
      const memberAttendanceRate = getMemberAttendanceRate(member);
      obj["Total Sessions"] = memberAttendanceRate.total;
      obj["Checkins"] = memberAttendanceRate.checkins;
      obj["Absents"] = memberAttendanceRate.absents;
      obj["Percentage"] = memberAttendanceRate.percentage + "%";
      sortsessions.forEach((session, index) => {
        if (session.checkins.length > 0) {
          let sessionName = session.name.replace(eventname, "");
          let sesionDate = getSessionDate(session.date).toLocaleString();

          const checkin = session.checkins.findIndex(
            (chekinmember) => chekinmember.email.toLowerCase() === email
          );
          let memberchecin = "";
          if (checkin !== -1) memberchecin = "PRESENT";
          obj[`${sessionName} ${sesionDate}`] = memberchecin;
        }
      });
      csvData.push(obj);
    });

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${eventname}.csv`;
    link.click();
  };

  const getSessionDate = (date) => {
    if (date._seconds) {
      return new Date(date._seconds * 1000);
    }
    return new Date(date);
  };

  const handleShowMemberModal = async (state) => {
    setMemberModalActive(state);
  };
  const handleCloseMemberModal = () => {
    setMemberModalActive((prev) => ({ ...prev, active: false }));
  };

  const getGraphData = (sessionsLocal) => {
    if (sessionsLocal && members.length) {
      const sessionsByMonth = {};
      sessionsLocal.map((session) => {
        const sessionDate = new Date(session.date);
        const month = sessionDate.toLocaleString("default", { month: "long" }); // Get month name
        if (!sessionsByMonth[month]) {
          sessionsByMonth[month] = [];
        }
        sessionsByMonth[month].push(session);
      });
      const data = [];
      for (const key in sessionsByMonth) {
        let monthAttendeceRateTotal = 0;
        if (sessionsByMonth[key]) {
          sessionsByMonth[key].map((session) => {
            monthAttendeceRateTotal +=
              (session.checkins.length / members.length) * 100;
          });
        }
        data.push({
          name: key,
          percentage: (
            monthAttendeceRateTotal / sessionsByMonth[key].length
          ).toFixed(),
        });
      }
      return data;
    }
  };

  const getEventAttendanceRate = () => {
    if (sessions && members) {
      let sessionsAttendanceRateTotal = 0;
      sessions.map((session) => {
        sessionsAttendanceRateTotal +=
          (session.checkins.length / members.length) * 100;
      });
      return (sessionsAttendanceRateTotal / sessions.length).toFixed();
    }
  };

  const getMemberAttendanceRate = (member) => {
    const memberEmail = member.email.toLowerCase();
    // let checkinsCounter = 0;
    if (sessions.length > 0) {
      // sessions.forEach((session) => {
      //   // checkinsCounter = 0;
      //   session.checkins.forEach((checkin) => {
      //     if (checkin.email === memberEmail) {
      //       checkinsCounter++;
      //     }
      //   });
      // });
      let checkinsCounter = 0;
      let sessensLength = 0;
      sessions.forEach((session, index) => {
        const sesionLength = session.checkins.length;
        if (sesionLength > 0) {
          sessensLength++;
          const checkin = session.checkins.findIndex(
            (chekinmember) => chekinmember.email.toLowerCase() === memberEmail
          );

          if (checkin !== -1) checkinsCounter++;
        }
      });
      // return (
      //   checkinsCounter +
      //   " " +
      //   ((checkinsCounter / sessions.length) * 100).toFixed()
      // );
      // return ((checkinsCounter / sessions.length) * 100).toFixed();
      const percentage = ((checkinsCounter / sessensLength) * 100).toFixed();
      return {
        checkins: checkinsCounter,
        percentage,
        absents: sessensLength - checkinsCounter,
        total: sessions.length,
      };
    }
    return 0;
  };

  const getMembersAbsents = (member) => {
    const memberEmail = member.email;
    let checkinsCounter = 0;
    if (sessions.length) {
      sessions.map((session) => {
        session.checkins.map((checkin) => {
          if (checkin.email === memberEmail) {
            checkinsCounter++;
          }
        });
      });
      return sessions.length - checkinsCounter;
    }
    return 0;
  };

  const getMembersCheckins = (member) => {
    const memberEmail = member.email;
    let checkinsCounter = 0;
    if (sessions.length) {
      sessions.map((session) => {
        session.checkins.map((checkin) => {
          if (checkin.email === memberEmail) {
            checkinsCounter++;
          }
        });
      });
      return checkinsCounter;
    }
    return 0;
  };

  // useEffect(()=>{
  const fetchData = async (event) => {
    // console.log('fetchData=>',event)
    // const getEvent = httpsCallable(functions, "getEvent");

    try {
      // const result = await getEvent({
      //   institutionName: institutionName,
      //   eventName: eventname,
      // });

      // const sortsessions = result.data.sessions.sort((a, b) => {
      //   const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      //   const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }

      //   // names must be equal
      //   return 0;
      // });

      const newsessions = [...event.sessions].map((item) => {
        if (item.date._seconds) {
          return { ...item, date: new Date(item.date._seconds * 1000) };
        }
        return { ...item, date: new Date(item.date) };
      });

      const sortsessions = newsessions.sort((a, b) => {
        const adate = new Date(a.date);
        const bdate = new Date(b.date);
        return bdate - adate;
      });
      // console.log(sortsessions);
      // console.log(setHours(setMinutes(new Date(), 30), 16))
      setSessions(sortsessions);

      const onlyp = [...event.members].filter((item) => {
        return item.role === "p";
      });

      const sortmembers = onlyp.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setMembers(sortmembers);
      // setMembers(result.data.members.sort((a, b) => (a.role === 'o' ? -1 : a.role === 'p' ? 1 : 0)));
      // setLoadingEvent(false);
    } catch (error) {
      console.error("Error getting event data:", error);
    }
  };

  const handleShowAddSessionModal = () => {
    setAddSessionModalActive(true);
  };
  const handleCloseAddSessionModal = () => {
    setAddSessionModalActive(false);
  };

  const handleEditEventName = () => {
    toast.info("coming soon ✨.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <>
      {loading ? (
        <LoadingAction
          position='fixed'
          text={"loading event"}
          transparency={100}
        />
      ) : (
        <div className='flex flex-col lg:flex-row w-full lg:h-screenNav bg-background'>
          <div className='flex gap-4 flex-col w-full lg:h-full lg:w-3/12 p-4 pb-0 lg:pr-0 lg:pb-4'>
            <div className='flex p-4 justify-center flex-col w-full bg-white rounded-xl  relative'>
              <p className='text-2xl'>{eventname}</p>
              <button
                onClick={handleEditEventName}
                className='hover:bg-grayish p-2 rounded-xl absolute right-2 bg-white'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='24'
                  viewBox='0 -960 960 960'
                  width='24'
                >
                  <path d='M200-200h56l345-345-56-56-345 345v56Zm572-403L602-771l56-56q23-23 56.5-23t56.5 23l56 56q23 23 24 55.5T829-660l-57 57Zm-58 59L290-120H120v-170l424-424 170 170Zm-141-29-28-28 56 56-28-28Z' />
                </svg>
              </button>
            </div>
            <div className='flex flex-col w-full h-full bg-white rounded-xl  overflow-hidden'>
              <div className='flex w-full mt-2 justify-center'>
                <div
                  onClick={handleShowAddSessionModal}
                  className='flex z-10 absolute left-4 ml-2 bg-primary w-10 h-10 rounded-full justify-center items-center text-white cursor-pointer'
                >
                  <span className='material-symbols-rounded'>add</span>
                </div>
                <div className='flex items-center'>
                  <p
                    id='dashboard-list-title'
                    className='w-full h-fit text-2xl text-primary text-center'
                  >
                    Sessions: {sessions.length} Members: {members.length}
                  </p>
                </div>
              </div>
              <SimpleBar
                style={{ maxHeight: "93%", paddingLeft: 40, paddingRight: 40 }}
              >
                {sessions ? (
                  sessions.map((session, i) => {
                    const sessionDate = new Date(session.date);
                    // const month = sessionDate.toLocaleString("default", {
                    //   month: "long",
                    // }); // Get month name
                    const month = sessionDate.toLocaleDateString("en-GB"); // Get Date
                    return (
                      <div
                        key={i}
                        id={session.name}
                        className='mb-1 mx-4 lg:mx-0 p-2 lg:p-0 border-b-2 border-grayish last:border-b-0'
                      >
                        <Link
                          to={`/session/${institutionName}/${eventname}/${session.name}`}
                        >
                          <div className='h-full w-full p-2.5 bg-white flex-1 flex items-left relative text-base'>
                            {month} {session.name.replace(eventname, "")}
                          </div>

                          {/* <Session
                            sessionName={session.name}
                            date={session.date}
                          /> */}
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </SimpleBar>
            </div>
          </div>
          <div className='flex flex-col h-full w-full lg:w-9/12 p-4 gap-4'>
            <div
              className={`flex h-96 lg:h-3/5 w-full bg-white rounded-xl  p-4 ${
                memberListExpanded ? "hidden" : ""
              }`}
            >
              {/* <ResponsiveContainer width='100%' height='100%'>
                <AreaChart
                  width={500}
                  height={400}
                  data={getGraphData(sessions)}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name' />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type='monotone'
                    dataKey='percentage'
                    stroke='#32DE8A'
                    fill='#64F0AC'
                  />
                </AreaChart>
              </ResponsiveContainer> */}
            </div>

            <div
              id='dahsboard-info-container-bottom'
              className={`flex flex-col lg:flex-row lg:h-${
                memberListExpanded ? "full" : "2/5"
              } overflow-hidden items-center lg:items-stretch w-full gap-4 `}
            >
              {/* <div
                className={` ${
                  memberListExpanded ? "hidden" : ""
                } w-full h-96 lg:h-full lg:w-1/4 flex justify-center items-center flex-col bg-white rounded-xl gap-6`}
              >
                <p className='text-center text-xl'>attendance</p>
                <DoughnutProgress percentage={getEventAttendanceRate()} />
              </div> */}

              {/* <div
                className={`flex  ${
                  memberListExpanded ? "hidden" : ""
                } flex-col p-4 w-full lg:w-1/4 h-full items-center justify-center bg-white rounded-xl`}
              >
                {[
                  { label: "Sessions", value: sessions.length },
                  { label: "Members", value: members.length },
                ].map((item, index) => (
                  <div
                    className='flex justify-around items-center text-2xl w-4/5 h-1/3 border-b-2 border-slate-100 last:border-b-0'
                    key={index}
                  >
                    <span>{item.label}</span>
                    <span>{item.value}</span>
                  </div>
                ))}
              </div> */}
              <div style={{ backgroundColor: "#fff", zIndex: 9 }}>
                {memberListExpanded && (
                  <button
                    onClick={() => setAddMembersModalActive(true)}
                    className='absolute right-40 flex justify-center items-center p-2 z-10'
                  >
                    add participants
                    <span className='material-symbols-rounded'>person_add</span>
                  </button>
                )}
                {memberListExpanded && (
                  <p
                    className='absolute cursor-pointer right-20 flex justify-center items-center p-2 z-10'
                    onClick={() => handleExportCSV()}
                  >
                    export
                    <span className='material-symbols-rounded'>download</span>
                  </p>
                )}
              </div>
              <div
                className={`${
                  memberListExpanded ? "w-full" : "w-full lg:w-2/4"
                } overflow-hidden relative bg-white rounded-xl`}
              >
                {/* <button
                  className='absolute right-0 flex justify-center items-center p-2 z-10'
                  onClick={() => setMemberListExpanded((prev) => !prev)}
                >
                  {memberListExpanded ? "shrink" : "expand"}{" "}
                  <span className='material-symbols-rounded'>
                    {memberListExpanded ? "unfold_less" : "unfold_more"}
                  </span>
                </button> */}

                <SimpleBar
                  style={{
                    maxHeight: "100%",
                  }}
                >
                  <table className='w-full border-collapse mt-4'>
                    <tr>
                      <th className='text-left text-xl p-2.5'>Name</th>
                      {/* <th className='text-center text-xl p-4'>Role</th> */}
                      {memberListExpanded && (
                        <th className='text-left text-xl p-2.5'>Absents</th>
                      )}
                      <th className='text-left text-xl p-2.5'>Attendance</th>
                      <th className='text-left text-xl p-2.5'>%</th>
                    </tr>
                    {members.map((member, i) => {
                      const attendance = getMemberAttendanceRate(member);
                      return (
                        <Member
                          onClick={() =>
                            handleShowMemberModal({
                              name: member.name,
                              email: member.email,
                              role: member.role,
                              status: member.status,
                              percentage: attendance,
                              absents: attendance?.absents,
                              checkins: getMembersCheckins(member),
                              active: true,
                            })
                          }
                          name={member.name}
                          email={member.email}
                          role={member.role}
                          expanded={memberListExpanded}
                          absents={attendance?.absents}
                          percentage={attendance}
                          key={i}
                        />
                      );
                    })}
                  </table>
                </SimpleBar>
                {/* <div className='w-full flex justify-center'>
                  {memberListExpanded && (
                    <button
                      onClick={() => setAddMembersModalActive(true)}
                      className='flex justify-center items-center gap-2 text-xl bg-primary text-white rounded-xl p-4 absolute bottom-2'
                    >
                      add participants
                      <span className='material-symbols-rounded'>
                        person_add
                      </span>
                    </button>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          {memberModalActive.active && (
            <MemberModal
              checkins={memberModalActive.checkins}
              absents={memberModalActive.absents}
              percentage={memberModalActive.percentage}
              email={memberModalActive.email}
              name={memberModalActive.name}
              status={memberModalActive.status}
              role={memberModalActive.role}
              fetch={fetchData}
              onClose={handleCloseMemberModal}
              eventName={eventname}
              institutionName={institutionName}
            />
          )}
          {addSessionModalActive && (
            <AddSessionModal
              sessionNumber={sessions.length}
              eventName={eventname}
              institutionName={institutionName}
              onClose={handleCloseAddSessionModal}
            />
          )}
          {addMembersModalActive && (
            <AddMembersModal
              institution={institutionName}
              event={eventname}
              onClose={() => setAddMembersModalActive(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default EventDashboard;
