import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
//Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCC9jI_Vx9qiLFOpQtiUyyQpuYOg3IlfqM",
  authDomain: "i-checkin-app.firebaseapp.com",
  projectId: "i-checkin-app",
  storageBucket: "i-checkin-app.appspot.com",
  messagingSenderId: "178225689240",
  appId: "1:178225689240:web:93951b475de45a9065d6ed",
  measurementId: "G-LEX2QDZERB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, "europe-west1");
