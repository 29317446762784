import React from "react";
import LoadingAnimation from "../assets/images/LoadingAnimationGreen.gif";
const Loading = () => {
  return (
    <div className="flex overflow-hidden w-screen h-screen bg-white justify-center items-center">
      <img className="w-72" src={LoadingAnimation} alt="loading animation" />
    </div>
  );
};

export default Loading;
