import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import DoughnutProgress from "../Components/DoughnutProgress";
import Checkin from "../Components/Checkin";
import Absent from "../Components/Absent";
import LoadingAction from "../Components/LoadingAction";
import { db } from "../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { toast } from "react-toastify";

import {
  useFetchSesion,
  useSesionSelector,
  useLoadingSelector,
} from "../state/eventsHooks";

const Session = () => {
  const { institutionName, eventname, sessionname } = useParams();
  const [absentMembers, setAbsentMembers] = useState([]);
  const [sessionActive, setSessionActive] = useState(false);
  const [checkedin, setCheckedin] = useState();
  const [sessionData, setSessionData] = useState();
  const [startingSession, setStartingSession] = useState(false);
  // const [loadingSession, setLoadingSession] = useState(true);
  // const [counter, setCounter] = useState(0);
  const QRWindow = useRef();

  const sesion = useSesionSelector();
  const callFetchSesion = useFetchSesion();
  const loading = useLoadingSelector();

  useEffect(() => {
    const sessionsRef = collection(
      db,
      `${institutionName}/${eventname}/sessions`
    );
    const sessionQuery = query(sessionsRef, where("name", "==", sessionname));
    const unsubscribe = onSnapshot(sessionQuery, (sessionSnapshot) => {
      if (!sessionSnapshot.empty) {
        const sessionDoc = sessionSnapshot.docs[0];
        const checkinRef = collection(sessionDoc.ref, "checkin");
        const unsubscribeCheckin = onSnapshot(checkinRef, (checkinSnapshot) => {
          // fetchData();
          callFetchSesion(institutionName, eventname, sessionname);
        });
        return () => {
          unsubscribe();
          unsubscribeCheckin();
        };
      }
    });
    return unsubscribe;
  }, []);

  // useEffect(()=>{
  //   callFetchSesion(institutionName, eventname, sessionname);
  // },[])

  useEffect(() => {
    if (sesion) {
      fetchData();
    }
  }, [sesion]);

  const fetchData = async () => {
    // const getSession = httpsCallable(functions, "getSession");
    try {
      // const result = await getSession({
      //   institutionName: institutionName,
      //   eventName: eventname,
      //   sessionname: sessionname,
      // });
      setCheckedin(sesion.checkins);
      setSessionData({
        name: sesion.name,
        status: sesion.status,
        date: sesion.date,
        qrDuration: sesion.qrduration,
        uid: sesion.uid,
      });

      const absentees = sesion.members.filter(
        (member) =>
          !sesion.checkins.some((checkin) => checkin.email === member.email)
      );
      setAbsentMembers(absentees);
      // setLoadingSession(false);
    } catch (error) {
      toast.error("failed to get event data 😟.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const handleStartSession = async () => {
    try {
      QRWindow.current = window.open(
        `${window.location.origin}/session-QRcode?i=${institutionName}&e=${eventname}&s=${sessionname}&d=${sessionData.qrDuration}&uid=${sessionData.uid}`,
        "RelatedWindow",
        "width=700,height=900,left=250, top=50"
      );
      const checkWindowStatus = setInterval(() => {
        // setCounter((prev) => prev + 1);
        if (QRWindow.current.closed) {
          handleStopSession();
          clearInterval(checkWindowStatus);
        }
      }, 1000);
      setSessionActive(true);
    } catch (err) {
      toast.error(`failed to start the session 💔 ${err.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleFocusSession = () => {
    QRWindow.current.focus();
  };

  const handleCopyInviteLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/invite?s=${sessionname}`
      );
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };
  const handleEditSessionName = () => {
    toast.info("coming soon ✨.", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleStopSession = async () => {
    setSessionActive(false);
    // setCounter(0);
    QRWindow.current.close();
    const deactivateSession = httpsCallable(functions, "deactivateSession");
    await deactivateSession({
      institutionName: institutionName,
      eventName: eventname,
      sessionName: sessionname,
    });
  };

  return (
    <>
      {loading ? (
        <LoadingAction
          position='fixed'
          text={"loading session"}
          transparency={100}
        />
      ) : (
        <main className='flex flex-col lg:flex-row w-full lg:h-screenNav p-4 bg-background gap-5 bg-cover'>
          <section className='flex flex-col gap-4 w-full lg:w-3/5'>
            <div className='h-full w-full p-4 bg-white flex-1 flex items-center rounded-xl  lg:px-10 relative'>
              <p className='text-2xl'>{sessionname}</p>
              {/* <button
                onClick={handleEditSessionName}
                className='hover:bg-grayish p-2 rounded-xl absolute right-2 bg-white'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='24'
                  viewBox='0 -960 960 960'
                  width='24'
                >
                  <path d='M200-200h56l345-345-56-56-345 345v56Zm572-403L602-771l56-56q23-23 56.5-23t56.5 23l56 56q23 23 24 55.5T829-660l-57 57Zm-58 59L290-120H120v-170l424-424 170 170Zm-141-29-28-28 56 56-28-28Z' />
                </svg>
              </button> */}
            </div>
            <div className='h-full w-full p-4 bg-white flex-1 rounded-xl flex items-center lg:px-10 overflow-clip relative'>
              <p className='text-2xl  whitespace-nowrap text-ellipsis'>{eventname}</p>
              {/* <button
                onClick={handleCopyInviteLink}
                className='hover:bg-grayish p-2 rounded-xl absolute right-2 bg-white'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='24'
                  viewBox='0 -960 960 960'
                  width='24'
                >
                  <path d='M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z' />
                </svg>
              </button> */}
            </div>
            <div className='h-2/5 w-full bg-white rounded-xl  p-4 overflow-hidden'>
              <p className='text-2xl text-center text-primary font-bold'>
                Checked in
              </p>
              <SimpleBar className='h-5/6 overflow-y-auto'>
                {checkedin &&
                  checkedin.map((checkinMember, i) => {
                    return (
                      <div
                        key={i}
                        className='border-b-2 border-grayish last:border-b-0'
                      >
                        <Checkin
                          name={checkinMember.name}
                          time={checkinMember.date}
                        />
                      </div>
                    );
                  })}
              </SimpleBar>
            </div>
            <div className='h-2/5 w-full bg-white rounded-xl  p-4 overflow-hidden'>
              <p className='text-2xl text-center text-red-500 font-bold'>
                Absent
              </p>
              <SimpleBar className='h-5/6 overflow-y-auto'>
                {absentMembers.map((member, i) => {
                  return (
                    <div
                      key={i}
                      className='border-b-2 border-grayish last:border-b-0'
                    >
                      <Absent
                        member={member}
                        institutionName={institutionName}
                        eventName={eventname}
                        sessionName={sessionname}
                      />
                    </div>
                  );
                })}
              </SimpleBar>
            </div>
          </section>
          <section className='w-full pb-40 min-h-fit lg:w-2/5 bg-white rounded-xl  flex-col justify-center items-center relative'>
            {/* <p className='text-2xl bg-grayish p-4 rounded-lg absolute left-0 m-4 '>{`${
              Math.floor(counter / 60) > 9
                ? Math.floor(counter / 60)
                : `0${Math.floor(counter / 60)}`
            } : ${counter % 60 > 9 ? counter % 60 : `0${counter % 60}`}`}</p> */}
            <div className='h-96 lg:h-4/6 w-full flex justify-center py-10'>
              <DoughnutProgress
                fontSize={44}
                percentage={(
                  (checkedin &&
                    checkedin.length /
                      (checkedin.length + absentMembers.length)) * 100
                ).toFixed()}
                height={"100%"}
              />
            </div>
            <div className='flex w-full flex-col text-3xl p-10 h-2/6'>
              <div className='flex w-full justify-between border-b-2 border-grayish p-4'>
                <p>checked in</p>
                <p>{checkedin&&checkedin.length}</p>
              </div>
              <div className='flex w-full justify-between p-4'>
                <p>absent</p>
                <p>{absentMembers.length}</p>
              </div>
            </div>
            <div className='w-full px-6 justify-center items-end  flex gap-6 h-1/6 absolute bottom-6'>
              {sessionActive ? (
                <button
                  className='flex-1 h-20  bg-red-500 rounded-2xl text-white text-3xl'
                  onClick={handleStopSession}
                >
                  Stop session
                </button>
              ) : (
                <button
                  className='flex-1 h-20  bg-primary rounded-2xl text-white text-3xl'
                  onClick={handleStartSession}
                >
                  {startingSession ? "starting..." : "Start session"}
                </button>
              )}
              {sessionActive && (
                <button
                  className='w-24 h-20 bg-primary rounded-2xl text-white text-3xl'
                  onClick={handleFocusSession}
                >
                  <span className='material-symbols-rounded '>qr_code</span>
                </button>
              )}
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default Session;
