import React from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import LoadingAction from "../LoadingAction";

const RemoveMemberModal = ({
  onClose,
  name,
  email,
  institutionName,
  eventName,
  fetch,
  closeMemberModal,
}) => {
  const [removing, setRemoving] = useState(false);

  const handleRemoveMember = async () => {
    setRemoving(true);
    const removeMember = httpsCallable(functions, "removeMember");
    await removeMember({ email, institutionName, eventName });
    fetch();
    setRemoving(false);
    onClose();
    closeMemberModal();
  };
  return (
    <div
      id="authentication-modal"
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-10 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full bg-gray-700 bg-opacity-10"
    >
      <div className="relative min-w-fit  w-full lg:w-1/3 max-h-full mx-auto mt-24">
        <div className="relative bg-white rounded-3xl shadow ">
          {removing && <LoadingAction />}
          <button
            onClick={onClose}
            type="button"
            className="absolute top-3 left-2.5 text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-md w-16 h-8 ml-auto inline-flex justify-center items-center opacity-100"
            data-modal-hide="authentication-modal"
          >
            <span className="material-symbols-rounded">arrow_back</span>
            <div className="pl-2">back</div>
          </button>
          <div className="px-6 py-6 lg:px-8 pt-10 ">
            <div className="pt-4 flex flex-col items-center gap-6 m-4">
              <p className="text-xl">are you sure you want to remove</p>
              <p className="bg-grayish text-4xl p-2 rounded-xl">{name}</p>
            </div>
            <div className="w-full h-fit gap-4 flex justify-around items-center p-4">
              <button
                onClick={handleRemoveMember}
                className="bg-red-500 w-full lg:w-1/3 h-16 rounded-2xl text-2xl text-white"
              >
                remove
              </button>
              <button
                onClick={onClose}
                className="bg-white w-full lg:w-1/3 h-16 rounded-2xl text-2xl text-black border"
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RemoveMemberModal;
