import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Event from "../Components/Event";
import AddEventModal from "../Components/Modals/AddEventModal";
import pastelColors from "../styles/pastelColors";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from "recharts";
// import { fetchEvents } from "../redux/actions";
// import { fetchEvents } from "../redux/eventsSlice";
// import { useSelector, useDispatch } from "react-redux";
import DoughnutProgress from "../Components/DoughnutProgress";
import LoadingAction from "../Components/LoadingAction";
// import { eventsSelector } from "../redux/eventsSlice";

import {
  useEventsSelector,
  useFetchEvents,
  useLoadingSelector,
} from "../state/eventsHooks";

const MainDashboard = () => {
  const [institutionChoice, setInstitutionChoice] = useState();
  // const events = useSelector((state) => state.events.data);
  const events = useEventsSelector(); //useSelector(eventsSelector);
  // const loading = useSelector((state) => state.events.loading);
  // const [loadingEvents, setLoadingEvents] = useState(true);
  // const error = useSelector((state) => state.events.error);
  const [totalAttendancePercentage, setTotalAttendancePercentage] = useState(0);
  const [totalEventsByMembers, setTotalEventsByMembers] = useState([]);
  const [eventsMainData, setEventsMainData] = useState([]);
  const [addEventModalActive, setAddEventModalActive] = useState(false);

  // const dispatch = useDispatch();
  const callFetchEvents = useFetchEvents();
  const loading = useLoadingSelector();

  useEffect(() => {
    callFetchEvents();
  }, []);

  useEffect(() => {
    if (events) {
      setInstitutionChoice(Object.keys(events)[0]);
    }
  }, [events]);
  useEffect(() => {
    if (events && institutionChoice) {
      getChartsData();
    }
  }, [events, institutionChoice]);

  const filterParticipants = (members) => {
    const filter = members.filter((participants) => {
      return participants.data.role === "p";
      // return participants.data.role.includes("p");
    });
    return filter.length;
  };

  const handleShowAddEventModal = async () => {
    setAddEventModalActive(true);
  };
  const handleCloseAddEventModal = () => {
    setAddEventModalActive(false);
  };

  const getChartsData = () => {
    if (events) {
      let totalCheckins = 0;
      let totalMembers = 0;
      let totalSessions = 0;
      const EventsByMembersList = [];
      const EventsMainData = [];
      getSortEventsInstitutionChoice(events[institutionChoice]).map(
        (event, i) => {
          if (event.sessions.length) {
            EventsByMembersList.push({
              eventName: event.eventName,
              totalMembers: filterParticipants(event.members),
              fill: pastelColors[i % pastelColors.length],
            });
            totalMembers += filterParticipants(event.members);
            totalSessions += event.sessions.length;
            const eventCheckins = event.sessions.map((session) => {
              totalCheckins += session.data.checkins.length;
              return session.data.checkins;
            });

            EventsMainData.push({
              event: event.eventName,
              absents:
                filterParticipants(event.members) * event.sessions.length -
                eventCheckins.flat().length,
              checkins: eventCheckins.flat().length,
            });
          }
        }
      );
      setTotalAttendancePercentage(
        ((totalCheckins / (totalMembers * totalSessions)) * 100).toFixed()
      );
      setTotalEventsByMembers(EventsByMembersList);
      setEventsMainData(EventsMainData);
      // setLoadingEvents(false);
    }
  };

  const getSortEventsInstitutionChoice = (arr) => {
    return [...arr].sort((a, b) => {
      const nameA = a.eventName.toUpperCase(); // ignore upper and lowercase
      const nameB = b.eventName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    // return arr;
  };

  return (
    <div className='flex flex-col lg:flex-row w-full lg:h-screenNav bg-background'>
      {loading ? (
        <LoadingAction
          position='fixed'
          text={"loading data"}
          transparency={100}
        />
      ) : (
        <>
          <div className='flex flex-col gap-4 w-full lg:h-full lg:w-3/12 p-4 pb-0 lg:pb-4 lg:pr-0'>
            <select
              className='p-4 text-2xl rounded-xl h-16 bg-white  '
              onChange={(e) => setInstitutionChoice(e.target.value)}
              value={institutionChoice}
              name='institutions'
              id='select-institution'
            >
              {events && institutionChoice ? (
                Object.keys(events).map((institution, i) => {
                  return <option key={i}>{institution}</option>;
                })
              ) : (
                <></>
              )}
            </select>
            <div className='flex flex-col w-full h-full bg-white rounded-xl relative overflow-hidden'>
              <div className='flex w-full justify-center p-2'>
                <div
                  onClick={handleShowAddEventModal}
                  className='flex absolute left-0 ml-4 bg-primary w-10 h-10 rounded-full justify-center items-center text-white cursor-pointer'
                >
                  <span className='material-symbols-rounded'>add</span>
                </div>
                <div className='flex items-center'>
                  <p
                    id='dashboard-list-title'
                    className='w-full h-fit text-3xl text-primary text-center'
                  >
                    events
                  </p>
                </div>
              </div>
              <SimpleBar
                style={{ maxHeight: "93%", paddingLeft: 40, paddingRight: 40 }}
              >
                {events && institutionChoice ? (
                  getSortEventsInstitutionChoice(events[institutionChoice]).map(
                    (event, i) => {
                      return (
                        <div
                          key={i}
                          id={event.eventName}
                          className='mb-1 p-2 lg:p-0 border-b-2 border-grayish last:border-b-0'
                        >
                          <Link
                            to={`/event/${institutionChoice}/${event.eventName}`}
                          >
                            <Event
                              eventName={event.eventName}
                              participants={
                                event.members
                                  ? filterParticipants(event.members)
                                  : 0
                              }
                            />
                          </Link>
                        </div>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
              </SimpleBar>
            </div>
          </div>
          <div className='flex flex-col h-full w-full lg:w-9/12 p-4 gap-4'>
            <div className='flex h-96 lg:h-3/5 w-full bg-white rounded-xl  p-4'>
              <ResponsiveContainer>
                <BarChart
                  width={"100%"}
                  height={300}
                  data={eventsMainData ? eventsMainData : ""}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='event' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey='checkins' stackId='a' fill='#64F0AC' />
                  <Bar dataKey='absents' stackId='a' fill='#FF675F' />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div
              id='dashboard-info-container-bottom'
              className='flex flex-col-reverse gap-4 lg:h-2/5 lg:flex-row items-center lg:items-stretch w-full'
            >
              {/* <div
                id='chart-container'
                className='flex w-full h-72 lg:h-auto  pb-4 lg:w-1/4 flex-col items-center bg-white rounded-xl'
              >
                <div className='flex h-1/4 mt-8'>
                  <span className='text-center text-xl'>all events</span>
                </div>
                <ResponsiveContainer width={"90%"} height={"50%"}>
                  <PieChart>
                    <Pie
                      data={totalEventsByMembers ? totalEventsByMembers : []}
                      dataKey='totalMembers'
                      nameKey='eventName'
                      cx='50%'
                      cy='50%'
                      outerRadius={"100%"}
                      fill='#fff'
                    />
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div> */}
              <div className='flex w-full lg:w-1/4 h-72 lg:h-auto pb-4 flex-col items-center bg-white rounded-xl'>
                <div className='flex h-1/4 mt-8'>
                  <span className='text-center text-xl h-1/2'>
                    attendance rate
                  </span>
                </div>
                <DoughnutProgress percentage={totalAttendancePercentage} />
              </div>

              {events && institutionChoice ? (
                <div className='flex flex-col p-4 w-full lg:w-2/4 h-full items-center justify-center bg-white rounded-xl'>
                  {[
                    {
                      label: "Events",
                      value: events[institutionChoice].length,
                    },
                    {
                      label: "Sessions",
                      value: events[institutionChoice]
                        .map((event) => event.sessions)
                        .flat().length,
                    },
                    {
                      label: "Attendees",
                      value: events[institutionChoice]
                        .map((event) => filterParticipants(event.members))
                        .reduce((partialSum, a) => partialSum + a, 0),
                    },
                  ].map((item, index) => (
                    <div
                      className='flex justify-around items-center text-2xl w-4/5 h-1/3 border-b-2 border-slate-100 last:border-b-0'
                      key={index}
                    >
                      <span>{item.label}</span>
                      <span>{item.value}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {addEventModalActive && (
            <AddEventModal
              institution={institutionChoice}
              onClose={handleCloseAddEventModal}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MainDashboard;
