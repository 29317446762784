import { NavLink } from "react-router-dom";
import wordLogo from "../assets/images/wordLogo.png";
const Footer = () => {
  return (
    <div
      id="footer"
      className="box-border px-4 mx-auto border-solid md:px-6 lg:px-8 max-w-7xl"
    >
      <div className="relative flex flex-col items-start justify-between leading-7 text-gray-900 border-0 border-gray-200 md:flex-row md:items-center">
        <NavLink
          to={"/"}
          className="left-0 flex items-center justify-center order-first w-full mb-4 font-medium text-gray-900 md:justify-start md:absolute md:w-64 lg:order-none lg:w-auto title-font lg:items-center lg:justify-center md:mb-0"
        >
          <span className="text-xl font-black leading-none text-gray-900 select-none logo">
            <img className="w-24 m-4" src={wordLogo} alt="word logo" />
          </span>
        </NavLink>
        <ul className="box-border flex justify-center items-center flex-wrap mx-auto my-6 space-x-6">
          <li className="relative mt-2 leading-7 text-left text-gray-900 border-0 border-gray-200 md:border-b-0 md:mt-0">
            <a
              href="mailto:info@i-checkin.app"
              className="box-border items-center block w-full px-4 text-base font-normal leading-normal text-gray-900 no-underline border-solid cursor-pointer hover:text-primary focus-within:text-primary sm:px-0 sm:text-left"
            >
              info@i-checkin.app
            </a>
          </li>
          <li className="relative mt-2 leading-7 text-left text-gray-900 border-0 border-gray-200 md:border-b-0 md:mt-0">
            <NavLink
              to={"/privacy"}
              className="box-border items-center block w-full px-4 text-base font-normal leading-normal text-gray-900 no-underline border-solid cursor-pointer hover:text-primary focus-within:text-primary sm:px-0 sm:text-left"
            >
              privacy policy
            </NavLink>
          </li>
          <li className="relative mt-2 leading-7 text-left text-gray-900 border-0 border-gray-200 md:border-b-0 md:mt-0">
            <NavLink
              to={"/terms"}
              href="/terms"
              className="box-border items-center block w-full px-4 text-base font-normal leading-normal text-gray-900 no-underline border-solid cursor-pointer hover:text-primary focus-within:text-primary sm:px-0 sm:text-left"
            >
              terms and conditions
            </NavLink>
          </li>
          <li className="relative mt-2 leading-7 text-left text-gray-900 border-0 border-gray-200 md:border-b-0 md:mt-0"></li>
        </ul>
        <div className="box-border right-0 flex justify-center w-full mt-4 space-x-3 border-solid md:w-auto md:justify-end md:absolute md:mt-0">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/icheckin/"
            className="inline-flex items-center leading-7 text-gray-900 no-underline border-0 border-gray-200 cursor-pointer hover:text-primary focus-within:text-primary"
          >
            <svg
              width="38"
              height="38"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-primary"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.41333 7.00006C8.08048 7.00006 7 8.08054 7 9.41339V38.5866C7 39.9195 8.08049 41 9.41333 41H38.5867C39.9195 41 41 39.9195 41 38.5866V9.41339C41 8.08055 39.9195 7.00006 38.5867 7.00006H9.41333ZM14.6314 17.5588C16.2604 17.5588 17.5809 16.2382 17.5809 14.6093C17.5809 12.9803 16.2604 11.6598 14.6314 11.6598C13.0025 11.6598 11.6819 12.9803 11.6819 14.6093C11.6819 16.2382 13.0025 17.5588 14.6314 17.5588ZM20.2822 19.7385H25.1704V21.9779C25.1704 21.9779 26.4969 19.3249 30.1061 19.3249C33.3257 19.3249 35.9928 20.9109 35.9928 25.7453V35.9396H30.9271V26.9806C30.9271 24.1287 29.4046 23.8151 28.2444 23.8151C25.8367 23.8151 25.4245 25.8919 25.4245 27.3526V35.9396H20.2822V19.7385ZM17.2026 19.7386H12.0603V35.9396H17.2026V19.7386Z"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="pt-4 mt-4 leading-7 text-center text-gray-600 border-t border-gray-200 md:pt-6">
        <p className="box-border mt-0 text-sm border-0 border-solid">
          © 2023 I-Checkin. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
