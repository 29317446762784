import React from "react";
import { useState, useEffect } from "react";
const Invite = () => {
  const [variableValue, setVariableValue] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get("s");
    if (value) {
      setVariableValue(value);
    }
  }, []);
  return (
    <div className="flex justify-center items-center w-full h-screenNav bg-background lg:p-16">
      <div className="flex flex-col items-center text-center justify-center w-full h-full lg:w-3/5 bg-white rounded-3xl p-2 gap-14">
        <p className="text-xl  lg:text-5xl">you have been invited to</p>

        <p className="text-xl  lg:text-5xl text-white bg-primary rounded-xl p-4">
          {variableValue}
        </p>
        <p className="text-md lg:text-xl">start by downloading the app</p>
        <div className="flex flex-col lg:flex-row gap-4 justify-center items-center">
          <a>
            <img
              className="h-12 object-contain"
              src="/appstore-badge.svg"
              alt="icheckin-apple"
            />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=com.sobow.icheckin"
            target="_blank"
          >
            <img
              className="h-12 object-contain"
              src="/googleplay-badge.png"
              alt="icheckin-google"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Invite;
