import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Landing from "./screens/Landing";
import NotFound from "./screens/NotFound";
import Privacy from "./screens/Privacy";
import Terms from "./screens/Terms";
import Dashboard from "./screens/MainDashboard";
import Event from "./screens/EventDashboard";
import ProtectedRoute from "./Components/ProtectedRoute";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth } from "./firebase";
import "./styles/animations.css";
import Loading from "./screens/Loading";
import Session from "./screens/Session";
import Invite from "./screens/Invite";
import QRSession from "./screens/QRSession";
import Unsubscribe from "./screens/Unsubscribe";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((fbuser) => {
      if (fbuser) {
        setUser(fbuser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />
      <TransitionGroup>
        <CSSTransition key={location.key} timeout={400} classNames="fade">
          <Routes location={location}>
            <Route path="*" Component={NotFound} />
            <Route path="/" element={<Landing isLoggedIn={!!user} />} />
            <Route path="/privacy" Component={Privacy} />
            <Route path="/terms" Component={Terms} />
            <Route path="/invite" Component={Invite} />
            <Route path="/unsubscribe/:email/:uuid" Component={Unsubscribe} />
            <Route
              element={
                <ProtectedRoute redirectPath="/" isAllowed={!!user} />
              }
            >
              <Route path="/dashboard" Component={Dashboard} />
              <Route
                path="/event/:institutionName/:eventname"
                Component={Event}
              />
              <Route
                path="/session/:institutionName/:eventname/:sessionname"
                Component={Session}
              />
              <Route path="/session-QRcode" Component={QRSession} />
            </Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
