import { configureStore } from '@reduxjs/toolkit';
import eventsReducer from './state/eventsSlice';


const store = configureStore({
  reducer: {
    events: eventsReducer,
  },
});

export default store;