const pastelColors = [
  "#63BAF2",
  "#FB5D86",
  "#CFE592",
  "#977AEC",
  "#859FE8",
  "#6AFAA1",
  "#8AEAF3",
  "#E09CB5",
  "#9586C9",
  "#7AF2EB",
  "#65F973",
  "#F5686C",
  "#E2C48E",
  "#96DEE0",
  "#A48EBF",
  "#88ADD0",
  "#EEC68A",
  "#9BDACD",
  "#E3F488",
  "#9EA5DB",
  "#C7B091",
  "#C58F99",
  "#F86B80",
  "#BB70E2",
  "#DEDB87",
  "#67F6D9",
  "#CFBDA3",
  "#8C80DC",
  "#DF85F8",
  "#B587DD",
  "#83CAA9",
  "#5DF3EB",
  "#87DF72",
  "#7F85DA",
  "#8CACDE",
  "#85BDCE",
  "#BAA8CD",
  "#E86570",
  "#F96BBB",
  "#85A7EF",
  "#D2A9BD",
  "#E68A8F",
  "#DC98CE",
  "#A5CC92",
  "#8AA6C4",
  "#9486D6",
  "#E5C191",
  "#A97DD4",
  "#F47F72",
  "#74BAFA",
  "#84FAD1",
  "#9786CF",
  "#9882E8",
  "#885BFA",
  "#CDA9CA",
  "#80D599",
  "#746FE3",
  "#8DC2C8",
  "#DABE91",
  "#EF6BED",
  "#8BDFA6",
  "#EF69A3",
  "#E7906D",
  "#A299E5",
  "#89CAE0",
  "#8DE0C9",
  "#DCBB98",
  "#7D9AF1",
  "#8CD6DB",
  "#C3AC8F",
  "#F6A66F",
  "#B79CCB",
  "#97CFC7",
  "#5997F7",
  "#D599BE",
  "#62F979",
  "#CDA290",
  "#F1E06D",
  "#DB86D6",
  "#F0E577",
  "#E395E0",
  "#A2BFCC",
  "#E4BF83",
  "#CDCF95",
  "#D280A5",
  "#DCB599",
  "#A1B4D0",
  "#C59CC1",
  "#D49AA1",
  "#A3D4B4",
  "#CD9AB5",
  "#81CC81",
  "#CA7DDE",
  "#8AA1D3",
  "#91CEDC",
  "#9797C3",
  "#9CC9D4",
  "#D896BE",
  "#F3DC63",
  "#D18D96",
  "#F16D7A",
  "#8288DD",
  "#CAEB74",
  "#BA77D9",
  "#8ED280",
  "#ADFA54",
  "#56F8E7",
  "#86D0F0",
  "#CC88A8",
  "#C6968C",
  "#BFE77D",
  "#67E6FB",
  "#8181E1",
  "#CDE082",
  "#8FADC6",
  "#8684F0",
  "#9AD798",
  "#7F91D6",
  "#6562F8",
  "#9ADAD1",
  "#A4D1CC",
  "#B38DCA",
  "#8F8AC3",
  "#AB7CF0",
  "#D59AA0",
  "#69F36D",
  "#9ECFD1",
  "#AF73DC",
  "#8EB7D2",
  "#70CBE8",
  "#AAD19E",
  "#DB8ADD",
  "#C59F8D",
  "#E9E27E",
  "#75F676",
  "#9EC6D0",
  "#E17C8E",
  "#EE64E6",
  "#F873D5",
  "#AAD3C2",
  "#E18FE3",
  "#B19CDC",
  "#F476D9",
  "#D790EC",
  "#8BAAC6",
  "#C6B997",
  "#9462F9",
  "#6FC6EE",
  "#8BBDE2",
  "#E87DA9",
  "#E4EB79",
  "#6BE3AD",
  "#8892E0",
  "#A6F28D",
  "#CA988A",
  "#98D9D1",
  "#7DA5F8",
  "#E199A8",
  "#B0D581",
  "#84CEF8",
  "#BDD4A4",
  "#72E3B8",
  "#8C97DC",
  "#C7A398",
  "#A5DF8D",
  "#E68682",
  "#93A1D1",
  "#F68176",
  "#91E9D5",
  "#6C9AF6",
  "#7985E3",
  "#D193A1",
  "#EF8CD5",
  "#E264F8",
  "#B17EDE",
  "#5AE7F5",
  "#79DBF7",
  "#58F6F4",
  "#99E5B1",
  "#D76EE4",
  "#CBD290",
  "#C470EA",
  "#C4BC91",
  "#B3C698",
  "#D9A77F",
  "#9D9EC5",
  "#B98CD6",
  "#6363F1",
  "#7DF5B8",
  "#75E8B8",
  "#DA98DA",
  "#D5E29B",
  "#81B2D0",
  "#D9AA8B",
  "#BEEB69",
  "#CB88B6",
  "#68F6E9",
  "#6EA0E6",
  "#8CE0A4",
  "#E5AE8B",
];

export default pastelColors;
