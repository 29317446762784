import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { httpsCallable } from "firebase/functions";
import { functions, db } from "../firebase";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import LoadingAnimation from "../assets/images/ActionLoadingAnimation.gif";

// images
import dotPattern from "../assets/images/dotPattern.svg";
import Ziv from "../assets/images/Ziv.png";
import Amy from "../assets/images/Amy.png";
import Thiago from "../assets/images/Thiago.png";
import dashboard from "../assets/images/dashboard.png";
import BarChartPreview from "../assets/images/BarChartPreview.png";
import LineChartPreview from "../assets/images/LineChartPreview.png";
import EventPreview from "../assets/images/event.png";
import SessionPreview from "../assets/images/session.png";
import AppPreview from "../assets/images/app.png";
import PaperPlane from "../assets/images/PaperPlane.png";

const Landing = ({ isLoggedIn }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [emailStatus, setEmailStatus] = useState("");
  const [emailValidated, setEmailValidated] = useState(false);
  const [message, setMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");

  if (isLoggedIn) {
    return <Navigate to={"/dashboard"} replace />;
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const emailValidation = (e) => {
    // console.log("USER EAILLLLLLLLLL:::::", e.target.value);

    e.preventDefault();
    setUserEmail(e.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(e.target.value);

    if (isValid) {
      setEmailValidated(true);
      setMessage("");
    } else {
      setEmailValidated(false);
      setMessage("Oops, invalid email. Please correct it.");
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const sendEmails = httpsCallable(functions, "sendEmails");
      let uuid = uuidv4();
      // console.log("UIDDDDDDD", uuid);
      const result = await sendEmails({
        email: userEmail,
        template: "email-leads",
        name: userEmail.split("@")[0],
        subscribe: true,
        uuid: uuid,
      });
    } catch (err) {
      // console.log("send email error", err);
    }

    const leadDocRef = doc(db, "leads", userEmail);

    onSnapshot(
      leadDocRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          // Now you can access the data of the document
          if (data.delivery) {
            const { state } = data.delivery;
            if (state === "SUCCESS") {
              setIsLoading(false);
              // setEmailStatus('error')
              setEmailStatus("success");
            } else if (state === "ERROR") {
              setIsLoading(false);
              setEmailStatus("error");
            }
          }
        } else {
          // console.log("Document does not exist");
        }
      },
      (error) => {
        console.error("Error getting document:", error);
      }
    );
  };

  const handleCheckboxChange = (event) => {
    // Update the isChecked state with the new checkbox state
    setIsChecked(event.target.checked);
  };

  return (
    <div>
      <section className='w-full lg:h-screenNav items-center flex relative px-10 bg-white pb-14'>
        <div className='right-0 h-auto w-1/2 top-1/2 transform -translate-y-1/2 absolute opacity-20'>
          <div className='absolute inset-0 bg-gradient-to-b from-white via-transparent to-white' />
          <img src={dotPattern} alt='dot pattern' />
        </div>
        <div className='left-0 h-auto w-1/2 top-1/2 -translate-y-1/2 transform rotate-180 absolute opacity-20'>
          <div className='absolute inset-0 bg-gradient-to-b from-white via-transparent to-white' />
          <img src={dotPattern} alt='dot pattern' />
        </div>
        <div className='w-full relative h-auto p-4 xl:pt-32'>
          <div className='max-w-7xl relative mx-auto sm:px-4 xl:px-0 flex items-center lg:flex-row flex-col h-full'>
            <div className='w-full relative lg:w-1/2 flex-shrink-0 h-full flex flex-col xl:pr-0 lg:pr-5 lg:items-start sm:items-center justify-center space-y-10'>
              <h1 className='font-extrabold text-4xl sm:text-5xl xl:text-6xl sm:text-center lg:text-left relative tracking-tight'>
                Make Attendance Tracking Easy
              </h1>
              <div className='flex max-w-sm items-center lg:max-w-lg'>
                <div className='flex flex-shrink-0'>
                  <img
                    src={Thiago}
                    alt='Thiago avatar'
                    className='rounded-full w-12 h-12 border-2 border-white'
                  />
                  <img
                    src={Amy}
                    alt='Amy avatar'
                    className='rounded-full -ml-5 w-12 h-12 border-2 border-white'
                  />
                  <img
                    src={Ziv}
                    alt='Ziv avatar'
                    className='rounded-full -ml-5 w-12 h-12 border-2 border-white'
                  />
                </div>
                <p className='pl-3 lg:text-base text-sm'>
                  Join Us And Start Transforming Attendance Tracking At Any Of
                  Your Events
                  <br />
                </p>
              </div>
              <div className='w-full flex sm:flex-row flex-col sm:justify-center lg:justify-start font-medium sm:space-x-5 space-y-4 sm:space-y-0'>
                <p
                  onClick={() => scrollToSection("start")}
                  className='px-7 py-4 bg-gray-900 text-white border-2 border-gray-900 hover:bg-white hover:text-gray-900 transition-all ease-out duration-150 rounded-xl flex items-center justify-center text-lg hover:cursor-pointer'
                >
                  Get Started
                </p>
                <p
                  onClick={() => scrollToSection("features")}
                  className='px-7 flex items-center py-4 gap-2 bg-gray-100 border-2 border-gray-100 hover:border-gray-900 justify-center hover:bg-white transition-all ease-out duration-150 text-gray-900 rounded-xl text-lg hover:cursor-pointer'
                >
                  <span className>Learn More</span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='24'
                    viewBox='0 -960 960 960'
                    width='24'
                  >
                    <path d='m480-340 180-180-57-56-123 123-123-123-57 56 180 180Zm0 260q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z' />
                  </svg>
                </p>
              </div>
            </div>
            <div className='lg:w-1/2 flex-shrink-0 h-full lg:pt-0 pt-24'>
              <div className='relative h-full w-full pl-12 lg:pr-0 pr-10'>
                <div className='h-auto transform xl:translate-x-8 translate-y-2 w-32 sm:w-56 xl:w-64 absolute border border-gray-100 left-0 bottom-0 z-10 rounded-lg shadow-2xl overflow-hidden p-2 bg-white'>
                  <img src={LineChartPreview} alt='line chart preview' />
                </div>
                <div className='h-auto transform translate-x-5 lg:translate-x-6 -translate-y-5 xl:-translate-y-8 w-32 sm:w-56 xl:w-64 absolute border border-gray-100 right-0 z-10 top-0 rounded-xl shadow-2xl overflow-hidden p-2 bg-white'>
                  <img src={BarChartPreview} alt='bar chart preview' />
                </div>
                <div className='relative flex items-center justify-center p-3'>
                  <div
                    className='absolute inset-0 opacity-30 rounded-2xl blur-xl'
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg, rgb(68, 255, 154) -0.55%, rgb(68, 176, 255) 22.86%, rgb(139, 68, 255) 48.36%, rgb(255, 102, 68) 73.33%, rgb(235, 255, 112) 99.34%)",
                      WebkitFilter: "blur(40px)",
                      filter: "blur(40px)",
                    }}
                  />
                  <div className='bg-gray-100 transform rounded-xl p-2 shadow-2xl border border-gray-100 overflow-hidden h-auto w-full flex flex-col z-20 xs:z-0'>
                    <img
                      src={dashboard}
                      alt='dashboard preview'
                      className='h-auto opacity-100'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='features' className='py-20 bg-white'>
        <div className='flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12'>
          <div className='relative'>
            <h2 className='w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl'>
              {" "}
              Level Up Your Events
              <br />
            </h2>
            <p className='w-full py-8 mx-auto -mt-2 text-lg text-left text-gray-700 intro sm:max-w-3xl md:text-center'>
              I-Checkin is your all-in-one solution to streamline attendance
              tracking and unlock event insights.
              <br />
              Perfect for Universities, Institutions, Conferences, Event hosts,
              Wedding planners, and more...
            </p>
          </div>
          <div className='flex flex-col mb-8 animated fadeIn sm:flex-row'>
            <div className='flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last'>
              <img
                className='rounded-lg shadow-xl'
                src={EventPreview}
                alt='eventPreview'
                data-rounded='rounded-lg'
                data-rounded-max='rounded-full'
              />
            </div>
            <div className='flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16'>
              <p
                className='mb-2 text-md font-semibold leading-none text-left uppercase text-primary'
                data-primary='indigo-600'
              >
                intuitive
              </p>
              <h3 className='mt-2 text-2xl sm:text-left md:text-4xl'>
                Attendance Control Made Simple
              </h3>
              <p className='mt-5 text-lg text-gray-700 text md:text-left'>
                Effortlessly manage attendance with our intuitive interface,
                providing all essential statistics at your fingertips.
              </p>
            </div>
          </div>
          <div className='flex flex-col mb-8 animated fadeIn sm:flex-row'>
            <div className='flex items-center mb-8 sm:w-1/2 md:w-5/12'>
              <img
                className='rounded-lg shadow-xl'
                src={SessionPreview}
                alt='session preview'
                data-rounded='rounded-lg'
                data-rounded-max='rounded-full'
              />
            </div>
            <div className='flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16'>
              <p
                className='mb-2 text-md font-semibold leading-none text-left uppercase text-primary'
                data-primary='indigo-600'
              >
                robust
              </p>
              <h3 className='mt-2 text-2xl sm:text-left md:text-4xl'>
                Versatile For Any Event
              </h3>
              <p className='mt-5 text-lg text-gray-700 text md:text-left'>
                I-Checkin adapts to any event type, ensuring user-friendly
                simplicity and robust functionality, whether it's a one-time
                event or a recurring series.
              </p>
            </div>
          </div>
          <div className='flex flex-col mb-8 animated fadeIn sm:flex-row'>
            <div className='flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last'>
              <img
                className='rounded-lg shadow-xl'
                src={AppPreview}
                alt='app preview'
                data-rounded='rounded-lg'
                data-rounded-max='rounded-full'
              />
            </div>
            <div className='flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16'>
              <p
                className='mb-2 text-md font-semibold leading-none text-left uppercase text-primary'
                data-primary='indigo-600'
              >
                User Friendly
              </p>
              <h3 className='mt-2 text-2xl sm:text-left md:text-4xl'>
                Enhance Attendee Satisfaction
              </h3>
              <p className='mt-5 text-lg text-gray-700 text md:text-left'>
                Boost attendee experiences with our user-friendly QR code
                scanner, making event check-ins quick and enjoyable.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id='start'
        className='h-auto bg-gray-50 pb-20 xl:px-0 px-8 pt-12'
      >
        <div className='max-w-2xl mx-auto text-center'>
          <img
            className='w-12 h-12 mx-auto mb-5'
            src={PaperPlane}
            alt='plane email'
          />
          {emailStatus === "success" ? (
            <h2 className='text-3xl font-semibold pb-4'>
              Email sent, <br />
              Thank you for reaching out!
            </h2>
          ) : (
            <>
              {emailStatus === "error" ? (
                <>
                  <h2 className='mb-8 font-semibold leading-none sm:text-center text-left text-3xl sm:text-4xl md:text-5xl'>
                    Ready to get started?
                    <br />
                  </h2>
                  <p className='text-sm sm:text-base md:text-lg sm:text-center text-left mb-7 text-red-600 italic'>
                    {message === "" ? (
                      "Sorry, we had trouble sending your email. Please try again shortly."
                    ) : (
                      <span className='text-red-600 italic'>{message}</span>
                    )}
                  </p>
                </>
              ) : (
                <>
                  <h2 className='mb-8 font-semibold leading-none sm:text-center text-left text-3xl sm:text-4xl md:text-5xl'>
                    Ready to get started?
                    <br />
                  </h2>
                  <p className='text-sm sm:text-base md:text-lg sm:text-center text-left mb-7 text-gray-500'>
                    {message === "" ? (
                      "Start transforming your events today!"
                    ) : (
                      <span className='text-red-600 italic'>{message}</span>
                    )}
                  </p>
                </>
              )}
            </>
          )}
          <div className='flex sm:flex-row flex-col mb-5 rounded-2xl sm:rounded-full w-full sm:max-w-lg mx-auto bg-white border border-gray-200 justify-between items-center h-auto sm:h-16 relative'>
            <input
              onChange={(e) => emailValidation(e)}
              onPaste={(e) => emailValidation(e)}
              className='rounded-t-2xl sm:py-0 py-4 sm:text-left text-center sm:rounded-l-full w-full h-full sm:pl-8'
              type='email'
              placeholder='Enter Your Email Address'
              name='email'
            />
            <div className='sm:border-b-0 border-b sm:border-r border-gray-200 sm:h-7 h-0 sm:w-0 w-11/12' />
            {/* <button onClick={(e) => sendEmail(e)} disabled={!emailValidated || isLoading} className="bg-none text-primary px-8 sm:py-0 py-2.5 uppercase font-medium">start</button> */}
            <button
              className={`bg-none flex justify-center items-center text-primary w-28 sm:py-0 py-2.5 h-full uppercase font-medium text-sm rounded-r-full ${
                isLoading ? "" : ""
              } hover:cursor-pointer ${
                emailValidated && isChecked ? "bg-primary text-white " : ""
              }`}
              onClick={(e) => sendEmail(e)}
              disabled={!emailValidated || !isChecked || isLoading}
            >
              {isLoading ? (
                <img src={LoadingAnimation} className='w-auto h-10' />
              ) : (
                "Reach Out"
              )}
            </button>
          </div>

          <div className='flex justify-center items-center gap-2'>
            <input
              type='checkbox'
              className='accent-black md:accent-black'
              id='myCheckbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label for='myCheckbox' className='text-sm text-gray-400'>
              By selecting this checkbox, you consent to being added to our
              subscription list
            </label>
          </div>
        </div>
      </section>
      <section className='box-border pt-5 leading-7 text-gray-900 bg-white border-0 border-gray-200 border-solid pb-7'></section>
    </div>
  );
};

export default Landing;

// we will reach out and give you all the information you need to get started with<br />I-Checkin! <br />
