import React from "react";

const Checkin = ({ name, time }) => {
  function formatTime(inputTime) {
    const parts = inputTime.split(/, | /);
    const timeComponent = parts[1];
    const period = parts[2];
    const timeParts = timeComponent.split(":");
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const isPM = period.toLowerCase() === "p.m.";
    const formattedHours =
      isPM && hours !== 12 ? hours + 12 : hours === 12 ? 12 : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes}${
      isPM ? "pm" : "am"
    }`;
    return formattedTime;
  }

  return (
    <div className="w-full flex justify-center"> 
    <div className="w-full max-w-3xl flex items-center justify-between p-4">
      <p className="rounded-xl text-xl bg-grayish p-2">{formatTime(time)}</p>
      <p className="text-xl">{name}</p>
      <span className="material-symbols-rounded text-primary">check</span>
    </div>
    </div>
  );
};

export default Checkin;
