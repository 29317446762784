import React from "react";
import { useState } from "react";
import DoughnutProgress from "../DoughnutProgress";
import RemoveMemberModal from "./RemoveMemberModal";

const MemberModal = ({
  onClose,
  name,
  email,
  institutionName,
  eventName,
  fetch,
  status,
  role,
  percentage,
  absents,
  checkins,
}) => {
  const [removeMemberModalActive, setRemoveMemberModalActive] = useState(false);

  const handleShowRemoveMemberModal = async () => {
    setRemoveMemberModalActive(true);
  };
  const handleCloseRemoveMemberModal = () => {
    setRemoveMemberModalActive(false);
  };

  return (
    <div
      id="authentication-modal"
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-10 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full bg-gray-700 bg-opacity-10"
    >
      <div className="relative w-full lg:w-2/5 min-w-fit max-h-full mx-auto mt-6 xl:mt-24">
        <div className="relative bg-white rounded-3xl shadow ">
          <button
            onClick={onClose}
            type="button"
            className="absolute top-3 left-2.5 text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-md w-16 h-8 ml-auto inline-flex justify-center items-center opacity-100"
            data-modal-hide="authentication-modal"
          >
            <span className="material-symbols-rounded">arrow_back</span>
            <div className="pl-2">back</div>
          </button>
          <div className="px-6 py-6 lg:px-8 pt-10">
            <div className="flex flex-wrap w-full">
              <div className="w-full flex lg:flex-row flex-col gap-4 mt-4 mb-4">
                <p className="w-full lg:w-2/3 p-4 rounded-xl text-xl lg:text-3xl bg-grayish">
                  {name}
                </p>
                <p className=" w-full lg:w-1/3 p-4 rounded-xl text-xl lg:text-3xl bg-grayish">
                  {role === "o" ? "manager" : "participant"}
                </p>
              </div>
              <p className="w-full p-4 rounded-xl text-xl lg:text-3xl bg-grayish">
                {email}
              </p>
            </div>

            <div className="flex items-center h-48 mt-10">
              <div className="flex flex-col w-1/2 ">
                <div className="flex p-2 w-full justify-between border-b-2 border-gray">
                  <p className="text-xl">status</p>
                  <p className="text-xl">{status}</p>
                </div>
                <div className="flex p-2 w-full justify-between border-b-2 border-gray">
                  <p className="text-xl">checkins</p>
                  <p className="text-xl">{checkins}</p>
                </div>
                <div className="flex p-2 w-full justify-between">
                  <p className="text-xl">absents</p>
                  <p className="text-xl">{absents}</p>
                </div>
              </div>
              <div className="flex items-center justify-center h-full w-1/2">
                <DoughnutProgress
                height="90%"
                  percentage={percentage}
                />
              </div>
            </div>
            <div className="flex w-full justify-center">
              <button
                onClick={handleShowRemoveMemberModal}
                className="flex mt-10 items-center justify-center gap-4 bg-red-500 w-full md:w-4/6 lg:w-4/6 h-16 rounded-2xl text-xl lg:text-2xl text-white"
              >
                remove from event{" "}
                <span className="material-symbols-rounded">person_remove</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {removeMemberModalActive && (
        <RemoveMemberModal
          fetch={fetch}
          email={email}
          name={name}
          eventName={eventName}
          institutionName={institutionName}
          onClose={handleCloseRemoveMemberModal}
          closeMemberModal={onClose}
        />
      )}
    </div>
  );
};
export default MemberModal;
