import React from "react";
import Logo from "../assets/images/redLogo.png";
import circles from "../assets/images/consmetic-circles.png";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center w-full h-screenNav bg-background p-4  lg:px-16 lg:py-8">
      <div className="flex w-full h-full rounded-xl bg-white">
        <div className="flex flex-col h-full w-full lg:w-1/2 justify-center gap-10 text-left items-center">
          <div className="flex flex-col w-full p-2 lg:w-2/3 gap-10">
            <p className="lg:text-5xl text-4xl font-extrabold">Ooops...</p>
            <p className="lg:text-4xl text-3xl">Page not found</p>
            <p className="lg:text-xl text-md">
              the page you are trying to reach was not found please try again,
              with a different link!
            </p>
            <button
              onClick={() => navigate(-1)}
              className="flex items-center justify-center gap-2 w-40 h-16 text-white text-2xl rounded-xl bg-primary"
            >
              <span className="material-symbols-rounded">arrow_back</span> go
              back
            </button>
          </div>
        </div>
        <div className="items-center justify-center h-full w-1/2 hidden lg:flex">
          <img className="w-3/12 z-10" src={Logo} alt="red logo" />
          <img
            className="w-2/5 absolute"
            src={circles}
            alt="consmetic circles"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
