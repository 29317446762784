const Terms = () => {
  return (
    <main className="w-3/5 mx-auto mb-8">
      <h1 class="policy-title text-3xl font-semibold text-center mb-6 text-primary">
        Terms and Conditions for i-Checkin
      </h1>
      <div class="policy-date text-center text-gray-600 mb-4">
        Last Updated: 05/09/2023
      </div>
      <p>
        Welcome to i-Checkin (the "Website"). These terms and conditions
        ("Terms") govern your use of the Website. By accessing or using the
        Website, you agree to be bound by these Terms. If you do not agree with
        these Terms, please do not use the Website.
      </p>

      <h2 className="text-2xl font-semibold mt-6">1. Acceptance of Terms</h2>

      <p>
        By using the Website, you agree to abide by these Terms, including any
        future amendments or modifications. i-Checkin reserves the right to
        update these Terms at any time, and your continued use of the Website
        after such changes constitutes your acceptance of the revised Terms.
      </p>

      <h2 className="text-2xl font-semibold mt-6">2. Privacy Policy</h2>

      <p>
        Your use of the Website is also governed by our Privacy Policy, which
        can be found at https://i-checkin.app/privacy. Please review this policy
        to understand how we collect, use, and protect your personal
        information.
      </p>

      <h2 className="text-2xl font-semibold mt-6">3. User Registration</h2>

      <ul class="list-disc list-inside text-gray-800 ml-6">
        <li>
          To access certain features of the Website, you may be required to
          register an account. You agree to provide accurate, current, and
          complete information during the registration proces
        </li>
        <li>
          You are responsible for maintaining the confidentiality of your
          account credentials and for any activities that occur under your
          account.
        </li>
        <li>
          You agree to notify i-Checkin immediately of any unauthorized use of
          your account or any other breach of security.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6">4. Use of the Website</h2>

      <ul class="list-disc list-inside text-gray-800 ml-6">
        <li>
          You may use the Website for lawful purposes only. You agree not to
          engage in any activity that violates applicable laws or infringes upon
          the rights of others.
        </li>
        <li>
          You may not attempt to gain unauthorized access to the Website or
          interfere with its proper functioning.
        </li>
        <li>
          i-Checkin reserves the right to terminate or suspend your access to
          the Website at any time for violation of these Terms.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6">5. Intellectual Property</h2>

      <ul class="list-disc list-inside text-gray-800 ml-6">
        <li>
          All content and materials on the Website, including but not limited to
          text, graphics, logos, and software, are protected by intellectual
          property laws and are the property of i-Checkin or its licensors.
        </li>
        <li>
          You may not reproduce, distribute, modify, or create derivative works
          from any content on the Website without the express written consent of
          i-Checkin.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6">
        6. Limitation of Liability
      </h2>
      <ul class="list-disc list-inside text-gray-800 ml-6">
        <li>
          i-Checkin makes no warranties or representations about the accuracy or
          completeness of the content on the Website. Your use of the Website is
          at your own risk.
        </li>
        <li>
          i-Checkin is not liable for any direct, indirect, incidental,
          consequential, or punitive damages arising out of your use of the
          Website.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6">7. Termination</h2>
      <p>
        i-Checkin reserves the right to terminate or suspend your access to the
        Website at any time without notice, for any reason, including, but not
        limited to, violation of these Terms.
      </p>

      <h2 className="text-2xl font-semibold mt-6">8. Governing Law</h2>
      <p>
        These Terms are governed by and construed in accordance with the laws of
        Israel.
      </p>

      <h2 className="text-2xl font-semibold mt-6">9. Contact Information</h2>
      <p>
        If you have any questions or concerns regarding these Terms, please
        contact us at info@i-checkin.app.
      </p>

      <p>
        By using the Website, you acknowledge that you have read, understood,
        and agree to be bound by these Terms and our Privacy Policy.
      </p>
    </main>
  );
};

export default Terms;
