import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { httpsCallable } from "firebase/functions";
import { functions, db } from "../firebase";
import LoadingAnimation from "../assets/images/LoadingAnimation.gif";
import Loading from "../assets/images/ActionLoadingAnimation.gif";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import React from "react";
import Logo from "../assets/images/redLogo.png";
import circles from "../assets/images/consmetic-circles.png";
import { useNavigate } from "react-router-dom";

const Unsubscribe = ({ isLoggedIn }) => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [successfullyUnsubscribed, setSuccessfullyUnsubscribed] =
    useState(true);

  const functionUnsubscribe = async () => {
    try {
      setIsLoading(true);
      const unsubscribeUser = httpsCallable(functions, "unsubscribeUser");
      const result = await unsubscribeUser({
        uuid: params.uuid,
        email: params.email,
      });
      const leadsRef = doc(db, "leads", params.email);

      getDoc(leadsRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const storedUuid = data.uuid;

            if (storedUuid === params.uuid) {
              const subscribed = data.subscribed;
              setClicked(true);
              // console.log("Subscribed:", subscribed);
              setIsLoading(false);
              subscribed
                ? setSuccessfullyUnsubscribed(false)
                : setSuccessfullyUnsubscribed(true);
              // subscribed ? setMessage('Successfully unsubscribed, we\'re sad to see you go...') : setMessage('We apologise, there has been an issue ')
            } else {
              // console.log("UUID does not match.");
            }
          } else {
            // console.log("Document does not exist.");
          }
        })
        .catch((error) => {
          console.error("Error getting document:", error);
          setIsLoading(false);
        });
    } catch (err) {
      // console.log("send email error", err);
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  return (
    <div className='flex justify-center items-center w-full h-screenNav lg:p-16 bg-background'>
      <div className='flex w-full h-full bg-white rounded-xl'>
        <div className='flex flex-col h-full w-full lg:w-1/2 justify-center gap-10 text-left items-center'>
          <div className='flex flex-col w-full p-2 lg:w-2/3 gap-10'>
            {!clicked ? (
              <>
                <p className='lg:text-4xl text-3xl font-bold text-center'>
                  Are you sure you want to unsubscribe?
                </p>
                <div className='w-full flex sm:flex-row flex-col justify-center font-medium sm:space-x-5 space-y-4 sm:space-y-0'>
                  {isLoading ? (
                    <a
                      onClick={() => functionUnsubscribe()}
                      className='px-7 flex items-center py-4 gap-2 bg-gray-100 border-2 border-gray-100 justify-center  transition-all ease-out duration-150 text-gray-900 rounded-xl text-lg  disabled'
                    >
                      Unsubscribing now...
                    </a>
                  ) : (
                    <>
                      <a
                        onClick={() => navigate("/")}
                        className='px-7 py-4 bg-gray-900 text-white border-2 border-gray-900 hover:bg-white hover:text-gray-900 transition-all ease-out duration-150 rounded-xl flex items-center justify-center text-lg hover:cursor-pointer'
                      >
                        No
                      </a>
                      <a
                        onClick={() => functionUnsubscribe()}
                        className='px-7 flex items-center py-4 gap-2 bg-gray-100 border-2 border-gray-100 hover:border-gray-900 justify-center hover:bg-white transition-all ease-out duration-150 text-gray-900 rounded-xl text-lg  hover:cursor-pointer'
                      >
                        Unsubscribe me
                      </a>
                    </>
                  )}
                </div>
              </>
            ) : successfullyUnsubscribed ? (
              <>
                <p className='lg:text-4xl text-3xl font-bold text-center'>
                  Successfully unsubscribed
                </p>
                <p className='lg:text-3xl text-2xl font-bold text-center'>
                  We're sad to see you go...
                </p>
                <div className='w-full flex sm:flex-row flex-col justify-center font-medium sm:space-x-5 space-y-4 sm:space-y-0'>
                  <a
                    onClick={() => navigate("/")}
                    className='px-7 flex items-center py-4 gap-2 bg-gray-100 border-2 border-gray-100 hover:border-gray-900 justify-center hover:bg-white transition-all ease-out duration-150 text-gray-900 rounded-xl text-lg  hover:cursor-pointer'
                  >
                    Back to homepage
                  </a>
                </div>
              </>
            ) : (
              <>
                <p className='lg:text-4xl text-3xl font-bold text-center'>
                  We apologise
                </p>
                <p className='lg:text-3xl text-2xl font-semibold text-center'>
                  There has been an issue unsubscribing you
                </p>
                <p className='lg:text-2xl text-xl font-semibold text-center text-red-600 italic'>
                  {" "}
                  please contact us via email
                </p>
                <div className='w-full flex sm:flex-row flex-col justify-center font-medium sm:space-x-5 space-y-4 sm:space-y-0'>
                  <a
                    onClick={() => navigate("/")}
                    className='px-7 flex items-center py-4 gap-2 bg-gray-100 border-2 border-gray-100 hover:border-gray-900 justify-center hover:bg-white transition-all ease-out duration-150 text-gray-900 rounded-xl text-lg  hover:cursor-pointer'
                  >
                    Back to homepage
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='items-center justify-center h-full w-1/2 hidden lg:flex'>
          <img src={LoadingAnimation} className='invert' />
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;
