import React from "react";
import { useState } from "react";
import Participant from "../Participant";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import LoadingAction from "../LoadingAction";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const AddSessionModal = ({
  onClose,
  eventName,
  institutionName,
  sessionNumber,
}) => {
  const [duration, setDuration] = useState(10);
  const [sessionName, setSessionName] = useState(
    `session ${sessionNumber + 1}`
  );
  const [creating, setCreating] = useState(false);
  const navigate = useNavigate();

  const handleCreateSession = () => {
    let sessionInfo = {
      institution: institutionName,
      event: eventName,
      sessionInfo: {
        name: sessionName,
        date: setHours(setMinutes(new Date(), 30), 16),
        qrDuration: duration,
        num: sessionNumber,
      },
    };
    const createSession = httpsCallable(functions, "createSession");
    setCreating(true);
    createSession(sessionInfo)
      .then((result) => {
        onClose();
        setCreating(false);
        navigate(`/session/${institutionName}/${eventName}/${sessionName}`);
      })
      .catch((err) => {
        setCreating(false);
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  return (
    <div
      aria-hidden='true'
      className='fixed bg-black top-0 left-0 right-0 z-10 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen bg-opacity-10'
    >
      <div className='relative w-1/2 max-h-full min-w-fit mx-auto mt-24'>
        <div className='relative bg-white rounded-3xl shadow '>
          {creating && <LoadingAction text={"creating session"} />}
          <button
            onClick={onClose}
            type='button'
            className='absolute top-3 left-2.5 text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-md w-16 h-8 ml-auto inline-flex justify-center items-center opacity-100'
            data-modal-hide='authentication-modal'
          >
            <span className='material-symbols-rounded'>arrow_back</span>
            <div className='pl-2'>back</div>
          </button>
          <div className='px-6 py-6 lg:px-8 pt-10 '>
            <div className='pt-4'>
              <p>session name</p>
              <input
                value={sessionName}
                onChange={(e) => setSessionName(e.target.value)}
                id='nameinput'
                className='w-full bg-grayish rounded-lg p-3 h-14'
              />
            </div>
            <div className='pt-4'>
              <p>qr code refresh duration</p>
              <label htmlFor='duration-picker'>{duration} seconds</label>
              <input
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                type='range'
                id='duration-picker'
                name='duration'
                min='5'
                max='60'
                className='w-full bg-grayish rounded-lg p-3 h-14'
              />
            </div>
            <div className='w-full h-fit flex justify-center items-center p-4'>
              <button
                onClick={handleCreateSession}
                className='bg-primary lg:w-1/3 w-40 h-16 rounded-2xl text-2xl text-white'
              >
                create
              </button>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default AddSessionModal;
