import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Label } from "recharts";

const DoughnutProgress = ({
  percentage = 0,
  height = "50%",
  fontSize = 24,
}) => {
  const cleanPercentage = Number(isNaN(percentage) ? 0 : percentage);
  const remainingPercentage = 100 - cleanPercentage;
  const colors = ["#64f0ac", "#ff675f"];

  const data = [
    { name: "AttendanceRate", value: cleanPercentage },
    { name: "AbsentsRate", value: remainingPercentage },
  ];

  return (
    <ResponsiveContainer width={"90%"} height={height}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={"65%"}
          outerRadius={"100%"}
          startAngle={90}
          endAngle={-270}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index]} />
          ))}
          <Label
            value={`${cleanPercentage}%`}
            position="center"
            fill="#333"
            fontSize={fontSize}
          />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DoughnutProgress;
