import LoadingAnimation from "../assets/images/ActionLoadingAnimation.gif";
const LoadingAction = ({ text, transparency = 90, position = "absolute" }) => {
  return (
    <div
      className={`${position} flex flex-col justify-center items-center bg-white w-full h-full z-20 opacity-${transparency} rounded-3xl`}
    >
      <img
        className="w-72"
        src={LoadingAnimation}
        alt="loading action animation"
      />
      <p className="text-2xl font-bold text-primary">{text}</p>
    </div>
  );
};
export default LoadingAction;
