const Member = ({ name, email, percentage, absents, expanded , role , onClick }) => {
  const color = percentage.percentage >= 75 ? "#64F0AC" : percentage.percentage >= 65 ? "#FFD390" : "#FF675F";
  return (
    <tr onClick={onClick} className="even:bg-grayish cursor-pointer hover:bg-primary hover:bg-opacity-20">
      <td className="text-left p-2.5 bg-white text-base">{name}</td>
      {/* <td className="text-xl text-center p-4"> {role === 'o' ? 'manager' : 'participant'}</td> */}
      {expanded && <td className="text-left p-2.5 bg-white text-base">{role === 'o' ? '-' : absents}</td>}
      <td style={{ color: color }} className="text-left p-2.5 bg-white text-base">{role === 'o' ? '-'  : `${percentage.checkins}`}</td>
      <td style={{ color: color }} className="text-left p-2.5 bg-white text-base">{role === 'o' ? '-'  : `${percentage.percentage}%`}</td>
    </tr>
  );
};

export default Member;
