import "./styles/event.css";
const Event = ({ participants, eventName }) => {
  return (
    <div id="event-container" className="flex justify-between h-20">
      <div id="event-left" className=" w-1/4 flex items-center justify-center ">
        <h1
          id="participants-bubble"
          className="flex  rounded-full h-12 w-12 items-center justify-center bg-grayish text-2xl"
        >
          {participants}
        </h1>
      </div>
      <div className="w-3/4 flex flex-col flex-auto ml-2 justify-center">
        <h1 className="">{eventName}</h1>
      </div>
    </div>
  );
};

export default Event;
